import APIClient from '@/shared/http/clients/api'

const resourceName = 'DespesasDiversos'
const APIBaseURLBusinessCommon = process.env.VUE_APP_BusinessCommon

const GetAllDespesas = (companyid, data = {}, config = {}) => APIClient.post(`${APIBaseURLBusinessCommon}/${resourceName}/GetAllDespesas/${companyid}`, data, config)
const GetTotalDespesas = (companyid, data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetTotal/${companyid}`, data, config)
const GetSubCategoriadiversos = (data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetSubCategoriadiversos`, data, config)
const PostDespesa = (companyID, data = {}, config = {}) => APIClient.post(`${APIBaseURLBusinessCommon}/${resourceName}/PostDespesa/${companyID}`, data, config)
const PutDespesas = (data = {}, config = {}) => APIClient.put(`${APIBaseURLBusinessCommon}/${resourceName}/PutDespesasDiversos`, data, config)
const GetAllDespesaEnviadas = (companyId, data = {}, config = {}) => APIClient.post(`${APIBaseURLBusinessCommon}/${resourceName}/GetAllDespesaEnviadas/${companyId}`, data, config)
const GetSubCategoria = (data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetSubCategoriadiversos`, data, config)
const SendExpenses = (filialId, tecnicoid, data = {}, config = {}) => APIClient.put(`${APIBaseURLBusinessCommon}/${resourceName}/SendExpenses/${filialId}/${tecnicoid}`, data, config)
const CheckIfUserCanSendExpenses = (companyId, data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/CheckIfUserCanSendExpenses/${companyId}`, data, config)
const GetFiltroDespesaHistorico = (filtro, companyId, data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetFiltroDespesaHistorico/${filtro}/${companyId}`, data, config)
const GetNotificacoes = (companyId, data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetNotificacoes/${companyId}`, data, config)
const GetFiltroDespesa = (companyId, filtro, data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetFiltroDespesa/${companyId}/${filtro}`, data, config)
const GetDespesa = (despesaId, data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetDespesa/${despesaId}`, data, config)
const ArquivoByDespesaId = (despesasId, data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetByDespesaDiversoId/${despesasId}`, data, config)
const Base64ByArquivoId = (arquivoId, data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/DownloadZpagConprovanteDiversos/${arquivoId}`, data, config)
const GetDespesasAprovadas = (data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetDespesasAprovadas`, data, config)

const expensesmiscellaneousRepository = {
  GetAllDespesas,
  GetTotalDespesas,
  GetSubCategoriadiversos,
  PostDespesa,
  PutDespesas,
  CheckIfUserCanSendExpenses,
  SendExpenses,
  GetAllDespesaEnviadas,
  GetSubCategoria,
  GetFiltroDespesaHistorico,
  GetNotificacoes,
  GetFiltroDespesa,
  GetDespesa,
  ArquivoByDespesaId,
  Base64ByArquivoId,
  GetDespesasAprovadas
}

export default expensesmiscellaneousRepository
