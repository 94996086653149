<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.ReimbursementAuthorization.LabelTitle }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.ReimbursementAuthorization.Description.LabelDescription }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-textarea
            id="description"
            name="description"
            :placeholder="$lang.ReimbursementAuthorization.Description.PlaceholderDescription"
            v-model="description"
            required
            autofocus
            rows="10"
            no-resize
          />
        </b-card-text>
        <div class="text-center align-self-end">
          <button
            type="button"
            class="btn btn-third text-bold"
            @click="onAdd"
          >
            <i class="fas fa-check-circle"/>&nbsp;{{ $lang.ReimbursementAuthorization.Description.ButtonOk }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'ReimbursementAuthorizationDescription',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      description: ''
    }
  },
  methods: {
    onAdd () {
      this.$router.push({ name: 'ReimbursementAuthorizationTerm', params: { ...this.$route.params, justificativa: this.description } })
    }
  }
}
</script>
