<template>
  <div class="app h-100">
    <div class="container-fluid d-flex align-items-center justify-content-center h-100 w-100">
      <transition name="fade">
        <router-view/>
      </transition>
    </div>
    <AppFooter ref="footer" style="display: none;" />
  </div>
</template>

<script>
import AppFooter from './layouts/Footer'

export default {
  name: 'PublicLayout',
  components: {
    AppFooter
  }
}
</script>
<style scoped>

.app{
  background-image: url(/static/img/logo/menu/BackgroundLogin2.png);
  background-size: cover;
}

</style>
