<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.MiscAuthorization.LabelTitle }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.MiscAuthorization.Construction.LabelDescription }}</div>
        <b-card-text class="w-100 my-3">
          <autocomplete-z
            ref="obraRHTypeahead"
            placeholder="Digite o nome da Obra"
            iconClass="fas fa-hard-hat"
            :serializer="(obra) => obra.codigo + ' - ' + obra.nome"
            :debounceLength="2"
            :formField="obra"
            v-on:search="onSearchObraRH"/>
        </b-card-text>
        <div class="text-center align-self-end">
          <button
            type="button"
            class="btn btn-third text-bold"
            @click="onAdd"
          >
            <i class="fas fa-plus-circle"/>&nbsp;{{ $lang.MiscAuthorization.Construction.ButtonAdd }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutocompleteZ from '@/components/forms/AutocompleteZ'
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'MiscAuthorizationConstruction',
  mixins: [
    ActionForms
  ],
  components: {
    AutocompleteZ
  },
  data () {
    return {
      obras: [],
      obra: {
        value: null,
        code: null,
        state: null
      }
    }
  },
  methods: {
    onAdd () {
      if (this.obra.id && this.obra.code && this.obra.name) {
        this.$router.push({ name: 'MiscAuthorizationRegional', params: { obraRHID: this.obra.id } })
      } else {
        this.toast.error(this.$lang.MiscAuthorization.Construction.Errors.ConstructionCodeRequired)
      }
    },
    onSearchObraRH (value) {
      this.loading.push()

      this.$database.businessCommon.obraRHRepository.GetAllByName({ empresaID: this.company.companyID, nome: { $regex: `${value}`, $options: 'i' } }, { limit: 5 })
        .fetch(data => {
          this.$refs.obraRHTypeahead.data = data
          this.loading.pop()
        }, error => {
          console.log(error)
          this.loading.pop()
        })
    }
  }
}
</script>
