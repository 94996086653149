<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.MiscExpensesDate.LabelTitle }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.MiscExpensesDate.LabelEnterExpenseDate }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-datepicker
            id="date"
            name="date"
            v-model="date"
            placeholder="dd/mm/yyyy"
            data-vv-as="date"
            v-validate="'required'"
            aria-describedby="dateErrorFeedback"
            :date-format-options="{day: 'numeric', month: 'numeric', year: 'numeric' }"
            :state="errors.has('date') ? false : getValidationState('date')"
          />
        </b-card-text>
        <div class="text-center align-self-end">
          <button type="button" class="btn btn-third text-bold" @click="onAdd"><i class="fas fa-plus-circle"/>&nbsp;{{ $lang.MiscExpensesDate.ButtonAdd }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'MiscExpensesDate',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      date: null
    }
  },
  computed: {
    isEditing: function () {
      return this.$route.params && this.$route.params.edit
    },
    editingRelease: function () {
      return this.$route.params.despesaItem
    }
  },
  methods: {
    onLoad () {
      if (this.isEditing && this.date === null) {
        this.date = this.moment(this.editingRelease.dataDespesa).format('YYYY-MM-DD')
      }
    },
    onAdd () {
      this.$validator.validateAll()
        .then(valid => {
          if (valid) {
            this.$router.push({ name: 'MiscExpensesQuantity', params: { ...this.$route.params, date: this.date } })
          } else {
            this.toast.error(this.$lang.MiscExpensesDate.Errors.ExpenseDateRequired)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
