import APIClient from '@/shared/http/clients/api'

const resourceName = 'authorize'
const APIAccess = process.env.VUE_APP_Core

const SignIn = (data = {}, config = {}) => APIClient.post(`${APIAccess}/${resourceName}`, data, config)
const SignOut = (data = {}, config = {}) => APIClient.post(`${resourceName}/signOut`, data, config)

const AuthRepository = {
  SignIn,
  SignOut
}

export default AuthRepository
