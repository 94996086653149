import APIClient from '@/shared/http/clients/api'

const resourceName = 'despesasViagem'
const APIBaseURLBusinessCommon = process.env.VUE_APP_BusinessCommon

const GetAllDespesas = (companyID, data = {}, config = {}) => APIClient.post(`${APIBaseURLBusinessCommon}/${resourceName}/GetAllDespesa/${companyID}`, data, config)
const GetTotalDespesas = (companyId, data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetTotal/${companyId}`, data, config)
// const GetEnumFiliacao = (data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetEnumFiliacao`, data, config)
const GetFiliacoes = (data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/GetFiliacoes`, data, config)
const SendExpenses = (coordenadorId, filialId, data = {}, config = {}) => APIClient.put(`${APIBaseURLBusinessCommon}/${resourceName}/SendExpenses/${coordenadorId}/${filialId}`, data, config)
const CheckIfUserCanSendExpenses = (companyId, data = {}, config = {}) => APIClient.put(`${APIBaseURLBusinessCommon}/${resourceName}/CheckIfUserCanSendExpenses/${companyId}`, data, config)
const Base64ByArquivoId = (arquivoId, empresaid, data = {}, config = {}) => APIClient.get(`${APIBaseURLBusinessCommon}/${resourceName}/DownloadZpagConprovante/${arquivoId}/${empresaid}`, data, config)

const miscellaneousExpenseRepository = {
  GetAllDespesas,
  GetTotalDespesas,
  // GetEnumFiliacao,
  GetFiliacoes,
  SendExpenses,
  CheckIfUserCanSendExpenses,
  Base64ByArquivoId
}

export default miscellaneousExpenseRepository
