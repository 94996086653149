import { database } from '../config'

let LocalDB
const resourceName = 'funcionario'

const GetResponsibleByCode = (
  selector = {},
  options = {},
  success = () => {},
  error = () => {}
) => database(`responsavel/codigo/${selector.codigo}`, resourceName, LocalDB)[resourceName].findOne(selector, options, success, error)

const FuncionarioRepository = (localDB) => {
  LocalDB = localDB
  return {
    GetResponsibleByCode
  }
}

export default FuncionarioRepository
