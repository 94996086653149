import { database } from '../config'

let LocalDB
const resourceName = 'cidade'

const GetAll = (
  selector = {},
  options = {}
) => database(`estado/${selector.estadoID}`, resourceName, LocalDB)[resourceName].find(selector, options)

const CidadeRepository = (localDB) => {
  LocalDB = localDB
  return {
    GetAll
  }
}

export default CidadeRepository
