import DespesaRepository from './repository/despesa'
import DespesaItemRepository from './repository/despesaitem'
import DespesaTipoRepository from './repository/despesatipo'
import ObraRHRepository from './repository/obrarh'
import SecaoRHRepository from './repository/secaorh'
import FuncionarioRepository from './repository/funcionario'
import TermoAceiteRepository from './repository/termoaceite'

const sync = async function () {
  for (const key in this) {
    // eslint-disable-next-line no-prototype-builtins
    if (typeof this[key] === 'object' && this[key] !== null && this[key].hasOwnProperty('Sync')) {
      await this[key].Sync(success => {
        console.log(success)
      }, error => {
        console.log(error)
      })
    }
  }
}

export default (localDB) => {
  return {
    despesaRepository: DespesaRepository(localDB),
    despesaItemRepository: DespesaItemRepository(localDB),
    despesaTipoRepository: DespesaTipoRepository(localDB),
    obraRHRepository: ObraRHRepository(localDB),
    secaoRHRepository: SecaoRHRepository(localDB),
    funcionarioRepository: FuncionarioRepository(localDB),
    termoAceiteRepository: TermoAceiteRepository(localDB),
    sync
  }
}
