import { database } from '../config'

let LocalDB
const resourceName = 'pais'

const GetAll = (
  selector = {},
  options = {}
) => database('', resourceName, LocalDB)[resourceName].find(selector, options)

const PaisRepository = (localDB) => {
  LocalDB = localDB
  return {
    GetAll
  }
}

export default PaisRepository
