import { database } from '../config'

let LocalDB
const resourceName = 'despesa'

const GetAllByAreaDespesa = (
  selector = {},
  options = {}
) => database(`area/${selector.area}`, resourceName, LocalDB)[resourceName].find(selector, options)

const GetFechamentos = (
  selector = {},
  options = {}
) => database(`fechamento/${selector.area}`, resourceName, LocalDB)[resourceName].find(selector, options)

const Upsert = (data, success) => database('', resourceName, LocalDB)[resourceName].upsert(data, success)

const Sync = (success, error) =>
  database(
    '',
    resourceName,
    LocalDB,
    data => {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('doc')) {
        if (data.doc.dataAcao !== data.base.dataAcao) {
          data = data.doc
        } else {
          data = data.doc
          return null
        }
      }

      return data
    }
  )[resourceName].upload(success, error)

const DespesaRepository = (localDB) => {
  LocalDB = localDB
  return {
    GetAllByAreaDespesa,
    GetFechamentos,
    Upsert,
    Sync
  }
}

export default DespesaRepository
