<template>
  <div class="input-group no-gutters">
    <span class="input-group-addon custom-group-addon" v-if="iconClass"><i :class="iconClass"></i></span>
    <b-form-input
      v-if="showCode"
      type="number"
      class=""
      style="text-align: center; max-width: 80px;"
      ref="codeInput"
      :placeholder="codePlaceholder || 'Código'"
      :autofocus="autofocus || false"
      v-model="formField.code"
      :state="formField.state"
      @change="onChangeCode" />
    <div class="col">
      <autocomplete
        ref="AutocompleteZComponent"
        v-model="query"
        :placeholder="placeholder"
        :serializer="serializer"
        :data="data"
        @hit="onHit"
        :inputClass="formField.state == null ? 'autocompleteClass' : 'autocompleteClass is-invalid'" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import { debounce } from 'lodash'
import { toast } from '@/shared/utils/UIHelper'

export default {
  name: 'AutocompleteZ',
  props: [
    'name',
    'formField',
    'placeholder',
    'codePlaceholder',
    'serializer',
    'debounceLength',
    'objId',
    'objCode',
    'iconClass',
    'autofocus',
    'showCode'
  ],
  data () {
    return {
      objSelected: {},
      query: '',
      noSearch: false,
      data: []
      // zref: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    getInfo: function () {
      let result = null
      if (this.objSelected) {
        result = this.objSelected
      }
      return result
    }
  },
  methods: {
    clear () {
      this.objSelected = {}
      this.query = ''
      this.data = []
      this.$refs.AutocompleteZComponent.$refs.inputValue = ''
    },
    handleInput (newValue) {
      this.$refs.AutocompleteZComponent.handleInput(newValue)
    },
    setValue (value) {
      if (!value) {
        // toast.warning(`Código ${this.formField.code} não encontrado.`, 'RESULTADO DA PESQUISA')
        toast.warning(this.$t('autocomplete.notfound'), 'RESULTADO DA PESQUISA')
        this.formField.code = null
        this.$refs.codeInput.$el.focus()
        return
      }

      this.objSelected = value
      this.formField.code = value.codigo
      const data = this.$refs.AutocompleteZComponent.serializer(value)
      this.$refs.AutocompleteZComponent.inputValue = data
      this.formField.value = value.id
      this.noSearch = true
      this.query = data
    },
    onChangeCode (value) {
      if (!value || value.trim().length === 0) {
        this.formField.value = null
        this.formField.code = null
        this.clear()
      } else {
        this.$emit('changeCode', value)
      }
    },
    onHit (value) {
      this.objSelected = value
      this.$emit('hit', value)
    }
  },
  watch: {
    query: debounce(function (data) {
      if (
        !data ||
        data.trim().length < parseInt(this.debounceLength) ||
        this.noSearch === true
      ) {
        this.data = []
        if (!data) {
          this.formField.value = null
          this.formField.code = null
          this.clear()
        }
      } else {
        this.$emit('search', data)
      }
      this.noSearch = false
    }, 800),
    objSelected: function (data) {
      this.noSearch = Boolean(this.query)

      let id = 'id'
      if (this.objId) {
        if (!data.hasOwnProperty(this.objId)) {
          throw new EvalError('Property {this.objId} not found.')
        }

        id = this.objId
      }

      let code = 'codigo'
      if (this.objCode) {
        if (!data.hasOwnProperty(this.objCode)) {
          throw new EvalError('Property {this.objCode} not found.')
        }

        code = this.objCode
      }

      this.formField.value = data[id] || null
      this.formField.code = data[code] || null
    }
  }
}
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/** Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
