import SeveralExpansesRepository from '../../../shared/http/repositories/Business/SeveralExpanses'
import expensesmiscellaneousRepository from '../../../shared/http/repositories/Business/expensesmiscellaneous'
import ActionForms from '@/shared/mixins/actionsForm.js'
import TermType from '@/shared/utils/termtype.js'
import { loading } from '@/shared/utils/UIHelper'

export default {
  mixins: [
    ActionForms,
    TermType
  ],
  components: {
  },
  data () {
    return {
      expenses: [],
      coordernador: '',
      filtro: null,
      dataSubCategoria: [],
      RepositoryExpense: null
    }
  },
  mounted () {
    this.RepositoryExpense = this.typeExpenses === 0 ? SeveralExpansesRepository : expensesmiscellaneousRepository
    this.getDespesas()
    this.getSubCategoriaEnum()
  },
  methods: {
    getDespesas () {
      loading.push()
      this.RepositoryExpense.GetAllDespesaEnviadas(this.company.companyID)
        .then(Response => {
          this.expenses = Response.data
          loading.pop()
        })
        .catch(() => loading.pop())
    },
    getSubCategoriaEnum () {
      loading.push()
      this.RepositoryExpense.GetSubCategoria()
        .then(Response => {
          loading.pop()
          this.dataSubCategoria = Response.data
        })
        .catch(() => loading.pop())
    },
    calTotal (expenses) {
      let total = 0
      let totalFormatado = ''
      let totalFormatadoCl = ''
      expenses.despesasEncaminhada.forEach((Element) => {
        if (Element.statusDespesa !== 5 && Element.statusDespesa !== 6) {
          total += Element.valor
          totalFormatado = total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
          totalFormatadoCl = 'CLP ' + total.toLocaleString('es-CL', { currency: 'CLP', minimumFractionDigits: 2 })
        }
      })
      return this.$i18n.locale === 'ptbr' ? totalFormatado : totalFormatadoCl
    },
    onChangeFiltro (e) {
      var value = e.value
      var categories = e.categories
      loading.push()
      if (this.typeExpenses === 0) {
        SeveralExpansesRepository.GetFiltroDespesaHistorico(categories, value, this.company.companyID)
          .then((response) => {
            this.expenses = response.data
            loading.pop()
          }).catch(() => {
            loading.pop()
          })
      } else {
        expensesmiscellaneousRepository.GetFiltroDespesaHistorico(value, this.company.companyID)
          .then((response) => {
            this.expenses = response.data
            loading.pop()
          }).catch(() => {
            loading.pop()
          })
      }
    },
    Atualiza () {
      window.location.reload()
    }
  }
}
