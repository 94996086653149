<template>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.ReimbursementMenu.LabelTitle }}</span>
    </div>
    <div class="card bg-third border-third mt-3 pointer">
      <div
        class="card-body text-bold text-center d-flex align-items-center justify-content-center"
        style="width: 80vw; min-height: 90px;"
        @click="onGoToReimbursementReleases"
      >
        <i class="fas fa-cart-plus" style="font-size: 32px;"/>
        <span class="ml-3" style="font-size: 22px;">{{ $lang.ReimbursementMenu.LabelReleases }}</span>
      </div>
    </div>
    <div class="card bg-third border-third mt-3 pointer">
      <div
        class="card-body text-bold text-center d-flex align-items-center justify-content-center"
        style="width: 80vw; min-height: 90px;"
        @click="onGoToReimbursementClosings"
      >
        <div class="d-flex align-items-center justify-content-center">
          <i class="fas fa-file-alt" style="font-size: 32px;"/>
          <span class="ml-3" style="font-size: 22px;">{{ $lang.ReimbursementMenu.LabelClosings }}</span>
        </div>
      </div>
    </div>
    <div class="card bg-third border-third mt-3 pointer">
      <div
        class="card-body text-bold text-center d-flex align-items-center justify-content-center"
        style="width: 80vw; min-height: 90px;"
        @click="onGoToReimbursementAuthorization"
      >
        <div class="d-flex align-items-center justify-content-center">
          <i class="fas fa-file-signature" style="font-size: 32px;"/>
          <span class="ml-3" style="font-size: 22px;">{{ $lang.ReimbursementMenu.LabelAuthorization }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReimbursementMenu',
  methods: {
    onGoToReimbursementReleases () {
      this.$router.push({ name: 'ReimbursementReleases' })
    },
    onGoToReimbursementClosings () {
      this.$router.push({ name: 'ReimbursementClosings' })
    },
    onGoToReimbursementAuthorization () {
      this.$router.push({ name: 'ReimbursementAuthorizationConstruction' })
    }
  }
}
</script>
