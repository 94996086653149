/* eslint-disable no-prototype-builtins */
import Guid from '@/shared/utils/guid.js'

export default {
  FormatNumber (valueNumber, max, precision) {
    if (valueNumber !== null && valueNumber !== '') {
      max = (max !== null && max !== '') ? max : 0
      precision = (precision !== null && precision !== '') ? precision : 0

      var tempNumber = valueNumber.toString().replace(',', '.')
      var valorNegativo = parseFloat(tempNumber) < 0
      if (valorNegativo) {
        tempNumber *= -1
      }
      var valorFinal = (parseFloat(tempNumber)).format(Array(max + 1).join('0') + '.' + Array(precision + 1).join('0'))
      valorFinal = this.FormatNumberToShow(valorFinal)
      if (valorNegativo) {
        valorFinal = '-'.concat(valorFinal)
      }
      return valorFinal
    } else {
      return ''
    }
  },
  FormatToShowDecimal (valueNumber) {
    if (valueNumber) {
      if (valueNumber !== null && valueNumber !== '') {
        var value = valueNumber + ''
        if (value.indexOf('.') > -1 || value.indexOf(',') > -1 || this.IsInt(valueNumber) || this.IsFloat(valueNumber)) {
          var newStringNumber = this.FormataDecimais(valueNumber, 2)
          return newStringNumber
        }
      }
    }
    return valueNumber
  },
  FormatToSaveDecimal (valueNumber) {
    if (valueNumber) {
      if (valueNumber !== null && valueNumber !== '') {
        var value = valueNumber + ''
        value = value.replace(',', '.')
        var newNumber = value * 1
        return newNumber
      }
    }
    return valueNumber
  },
  ValidBrazilianNumber (valueNumber) {
    if (valueNumber && valueNumber !== null) {
      var value = valueNumber + ''
      return (/^\d+(,\d{0,2})?$/).test(value + '')
    }
    return false
  },
  ValidAmericanNumber (valueNumber) {
    if (valueNumber && valueNumber !== null) {
      var value = valueNumber + ''
      return (/^\d+(\.\d{0,2})?$/).test(value + '')
    }
    return false
  },
  FormataDecimais (iValor, iDecimais) {
    if (iValor !== '' && iValor !== null) {
      var sValor = String(iValor)
      sValor = sValor.replace('.', ',')
      var iLen = sValor.length - 1
      var iPosPonto = sValor.indexOf(',')
      var iInd = 0
      var iLenDecimais = 0
      if (iPosPonto > 0) {
        iLenDecimais = (iLen - iPosPonto)
        iInd = iLenDecimais
      } else {
        if (iDecimais > 0) {
          sValor = sValor.concat(',')
        }
        iInd = 0
      }
      while (iInd < iDecimais) {
        sValor = sValor.concat('0')
        iInd = iInd + 1
      }
      return sValor
    } else {
      return ''
    }
  },
  Left (str, n) {
    var retorno = ''
    if (n <= 0) {
      retorno = ''
    } else if (n > String(str).length) {
      retorno = str
    } else {
      retorno = String(str).substring(0, n)
    }
    return retorno
  },
  Right (str, n) {
    var retorno = ''
    if (n <= 0) {
      retorno = ''
    } else if (n > String(str).length) {
      retorno = str
    } else {
      var iLen = String(str).length
      retorno = String(str).substring(iLen, iLen - n)
    }
    return retorno
  },
  IsInt (n) {
    return Number(n) === n && n % 1 === 0
  },
  IsFloat (n) {
    return Number(n) === n && n % 1 !== 0
  },
  FormatCode: function (code) {
    var value = code.toString()
    var zero = '0'
    var result = ''

    for (var i = value.length; i < 6; i++) {
      result = result + zero
    }
    return result + value
  },
  MinimizedSidebar: function () {
    if (!document.body.classList.contains('sidebar-minimized')) {
      document.body.classList.add('sidebar-minimized')
    }

    document.body.classList.remove('sidebar-mobile-show')
    window.scrollTo(0, 0)
  },
  ValidateForms: function (forms, data, self, toast) {
    var result = true

    for (var opt in forms) {
      if (forms[opt].hasOwnProperty('required') && forms[opt].required === true) {
        if (forms[opt].value === null || forms[opt].value === '') {
          toast.warning(self.$lang.Common.Alerts.RequiredField)
          forms[opt].state = false // 'invalid'
          // -->
          setTimeout(function (e) {
            forms[opt].state = null
          }, 7000)
          return false
        }// else forms[opt].state = true
      }
    }
    this.SetValueToSave(forms, data)

    return result
  },
  ValidateSimpleForms: function (forms, self, toast) {
    var result = true

    for (var opt in forms) {
      if (forms[opt].hasOwnProperty('required') && forms[opt].required === true) {
        if (forms[opt].value === null || forms[opt].value === '') {
          toast.warning(self.$lang.Common.Alerts.RequiredField)
          forms[opt].state = false // 'invalid'
          // -->
          setTimeout(function (e) {
            forms[opt].state = null
          }, 7000)
          return false
        }// else forms[opt].state = true
      }
    }

    return result
  },
  ClearDataForms: function (forms, data) {
    for (var opt in forms) {
      if (forms[opt].hasOwnProperty('defaultArray')) {
        forms[opt].value = []
      } else if (forms[opt].hasOwnProperty('defaultBoolean')) {
        forms[opt].value = false
      } else if (forms[opt].hasOwnProperty('default')) {
        forms[opt].value = forms[opt].default
      } else {
        forms[opt].value = null
      }

      if (forms[opt].hasOwnProperty('clearOption')) {
        if (forms[opt].clearOption) {
          forms[opt].options = []
        }
      }
    }

    for (var property in data) {
      data[property] = null
    }
  },
  SetValueInForm: function (forms, data, obj) {
    for (var key in data) {
      if (obj[key] !== Guid.Empty) {
        if (obj.hasOwnProperty(key)) {
          data[key] = obj[key]
          /*
          if (this.ValidBrazilianNumber(obj[key]) || this.ValidAmericanNumber(obj[key])) {
            data[key] = this.FormatToShowDecimal(obj[key])
          }
          */
        }
        if (forms.hasOwnProperty(key)) {
          forms[key].value = obj[key]
          /*
          if (this.ValidBrazilianNumber(obj[key]) || this.ValidAmericanNumber(obj[key])) {
            forms[key].value = this.FormatToShowDecimal(obj[key])
          }
          */
        }
      }
    }
  },
  SetFormDefaultValues: function (form) {
    for (var key in form) {
      if (form[key].hasOwnProperty('default') && form[key].default !== null && form[key].default !== undefined) {
        form[key].value = form[key].default
      } else {
        form[key].value = null
      }
    }
  },
  SetValueToSave: function (forms, data) {
    for (var key in forms) {
      if (data.hasOwnProperty(key)) {
        if (forms[key].hasOwnProperty('defaultArray')) {
          var options = forms[key].options
          data[key] = []
          for (var i in options) {
            data[key].push(options[i].value)
          }
        } else {
          data[key] = forms[key].value
          if (this.ValidBrazilianNumber(forms[key].value) || this.ValidAmericanNumber(forms[key].value)) {
            data[key] = this.FormatToSaveDecimal(forms[key].value)
          }
        }
      }
    }
  },
  IsValid: function (obj) {
    var result = false
    if (obj != null && obj !== '' && obj.length > 0) {
      this.result = true
    }
    return result
  },
  removeItemArray: function (array, value) {
    for (var i in array) {
      if (array[i].value === value) {
        array.splice(i, 1)
      }
    }
    return array
  },
  /**
   * Capitalizes first letters of words in string.
   * @param {string} str String to be modified
   * @param {boolean=false} lower Whether all other letters should be lowercased
   * @return {string}
   * @usage
   *   capitalize('fix this string');     // -> 'Fix This String'
   *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
   *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
  */
  capitalize: function (str, lower = false) {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())
  },
  GetResponse: function (response) {
    var result = null
    if (response && response.data.code >= 0) {
      result = response.data.data
    }
    return result
  }

}
