<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.MiscExpensesReleases.LabelTitle }}</span>
    </div>
    <div
      v-for="(item, index) in releases"
      :key="item.id"
      class="card mb-4 w-100"
    >
      <div class="card-body w-100">
        <div v-if="item.incorrida === true" class="canceled">
            <span class="canceled-text">INCORRIDA</span>
        </div>
        <tr class="w-100">
            <td class="keyColumn">Data:</td>
            <td class="valueColumn">
                <strong :style="item.incorrida === true ? 'text-decoration: line-through;' : ''">{{ moment(item.dataDespesa).format('DD/MM/yyyy') }}</strong>
            </td>
        </tr>

        <tr class="w-100">
            <td class="keyColumn">Descrição:</td>
            <td class="valueColumn">
                <strong :style="item.incorrida === true ? 'text-decoration: line-through;' : ''">{{ item.despesaTipo ? item.despesaTipo.nome : '' }}</strong>
            </td>
        </tr>

        <tr class="w-100">
            <td class="keyColumn">Observações:</td>
            <td class="valueColumn">
                <strong :style="item.incorrida === true ? 'text-decoration: line-through;' : ''">{{ item.observacao ? item.observacao : 'N/D' }}</strong>
            </td>
        </tr>

        <tr class="w-100">
            <td class="keyColumn">Tipo:</td>
            <td class="valueColumn">
                <span v-if="item.forma" class="text-success text-toupper"><strong :style="item.incorrida === true ? 'text-decoration: line-through;' : ''">CRÉDITO</strong></span>
                <span v-else class="text-danger text-toupper"><strong :style="item.incorrida === true ? 'text-decoration: line-through;' : ''">DÉBITO</strong></span>
            </td>
        </tr>

        <tr class="w-100">
            <td class="keyColumn">Quantidade:</td>
            <td class="valueColumn">
                <strong :style="item.incorrida === true ? 'text-decoration: line-through;' : ''">{{ item.quantidade }}</strong>
            </td>
        </tr>

        <tr class="w-100">
            <td class="keyColumn">Valor:</td>
            <td class="valueColumn">
                <strong :style="item.incorrida === true ? 'text-decoration: line-through;' : ''">{{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(item.valorUnitario) }}</strong>
            </td>
        </tr>

        <tr class="w-100">
            <td class="keyColumn">Total:</td>
            <td class="valueColumn">
                <strong :style="item.incorrida === true ? 'text-decoration: line-through;' : ''">{{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(item.valorTotal) }}</strong>
            </td>
        </tr>

        <tr class="w-100">
            <td class="keyColumn">Saldo:</td>
            <td class="valueColumn">
                <strong :style="item.incorrida === true ? 'text-decoration: line-through;' : ''">{{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(onGetBalance(index)) }}</strong>
            </td>
        </tr>
      </div>
    </div>
    <div
      class="card mb-4 w-100"
    >
      <div class="card-body w-100">
          <tr class="w-100">
            <td class="keyColumn">Saldo&nbsp;Final:</td>
            <td class="valueColumn">
                <strong :class="onGetBalance(this.releases.length - 1) >= 0 ? 'text-success' : 'text-danger'">{{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(onGetBalance(this.releases.length - 1)) }}</strong>
            </td>
        </tr>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'MiscExpensesClosingDetails',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      area: 1,
      releases: [],
      types: []
    }
  },
  computed: {
    despesa: function () {
      return this.$route.params.despesa
    }
  },
  mounted () {
    this.onGetDespesaItens(this.despesa.id)
    this.onGetDespesasTipos()
  },
  methods: {
    onGetDespesaItens (value) {
      if (value) {
        this.loading.push()
        this.$database.businessCommon.despesaItemRepository.GetAllByDespesa({ inquilinoID: this.accountLogged.InquilinoID, despesaID: value }, { sort: { dataDespesa: 1 } })
          .fetch(data => {
            this.releases = data
            this.loading.pop()
          }, error => {
            console.log(error)
            this.loading.pop()
          })
      }
    },
    onGetDespesasTipos () {
      this.$database.businessCommon.despesaTipoRepository.GetAllByDiversos({}, {})
        .fetch(data => {
          this.types = data
          this.loading.pop()
        }, error => {
          console.log(error)
          this.types = []
          this.loading.pop()
        })
    },
    onGetBalance (index) {
      const releases = this.releases

      let balance = 0

      for (let i = 0; i <= index; i++) {
        const release = releases[i]

        if (!release.incorrida) {
          if (release.forma === 1) {
            balance += release.valorTotal
          } else {
            balance -= release.valorTotal
          }
        }
      }

      return balance
    },
    despesaTipoNome (despesaTipoID) {
      return this.types.find(d => d.id === despesaTipoID).nome || 'N/D'
    }
  }
}
</script>

<style>
.canceled {
    position: absolute;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
}

.canceled-text {
    transform: rotate(-20deg);
    color: rgba(0, 0, 0, 0.26);
    font-size: 42px;
    font-weight: bold;
}

.keyColumn {
    background-color: #285C07 !important;
    padding: 0 5px;
    color: #fff;
    border: solid 1px rgba(0, 0, 0, 0.096);
}

.valueColumn {
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.096);
    padding: 0 10px;
}
</style>
