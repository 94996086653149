export const LOCALE_KEY = 'Z_PAG_LOCALE'

const SaveLocale = (locale) => window.localStorage.setItem(LOCALE_KEY, locale)

const GetLocale = (locale) => window.localStorage.getItem(LOCALE_KEY)

const DestroyLocale = () => window.localStorage.removeItem(LOCALE_KEY)

export const LocaleService = {
  SaveLocale,
  GetLocale,
  DestroyLocale
}
