<template>
  <div
    v-if="showConfirm"
    class="d-flex flex-grow-1 align-items-start mt-5 w-100"
  >
    <div class="card w-100">
      <div class="card-body">
        <h6 class="pb-2">
          Confirma a exclusão da despesa?
        </h6>
        <div class="d-flex justify-content-between">
          <button
            type="button"
            class="btn btn-danger text-bold"
            @click="onCancelDeleteExpense"
          >
            <i class="fas fa-times"/>&nbsp;Não
          </button>
          <button
            type="button"
            class="btn btn-success text-bold"
            @click="onConfirmDeleteExpense"
          >
            <i class="fas fa-check"/>&nbsp;Sim
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="showReleases"
    class="d-flex flex-grow-1 flex-column align-items-center"
  >
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.MiscExpensesReleases.LabelTitle }}</span>
    </div>
    <div class="text-center py-2">
      <button
        type="button"
        :disabled="!despesa || !despesa.id"
        class="btn btn-third text-bold px-3 py-2"
        @click="onAddExpense"
      >
        <i class="fas fa-plus-circle"/>&nbsp;{{ $lang.MiscExpensesReleases.ButtonAdd }}
      </button>
    </div>
    <div class="w-100 overflow-x-auto">
      <table class="table table-hover" style="border: 1px solid #dcdede;">
              <thead class="thead-third" style="border: solid 1px #0277bd; height: 44px;">
                <tr>
                  <th class="text-center">{{$lang.MiscExpensesReleases.DataTable.Actions}}</th>
                  <th class="text-center">{{$lang.MiscExpensesReleases.DataTable.Release}}</th>
                  <th class="text-center">{{$lang.MiscExpensesReleases.DataTable.Date}}</th>
                  <th class="text-center">{{$lang.MiscExpensesReleases.DataTable.Value}}</th>
                  <th class="text-center">{{$lang.MiscExpensesReleases.DataTable.Balance}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="releases.length === 0">
                  <td colspan="5" class="text-center">
                    {{$lang.Common.DataTable.NoRecordsFound}}
                  </td>
                </tr>
                <tr v-else v-for="(release, index) in releases" v-bind:key="release.id">
                  <td class="text-center d-flex">
                    <div
                      v-if="release.despesaTipo.forma === 0"
                      @click="onDeleteExpense(release)"
                    >
                      <i
                        class="fa fa-trash text-danger mx-2"
                        style="font-size: 24px;"
                        v-b-tooltip.hover
                        :title="$lang.Common.Buttons.Delete"
                      />
                    </div>
                    <div
                      v-if="release.despesaTipo.forma === 0"
                      @click="onEditExpense(release)"
                    >
                      <i
                        class="fa fa-edit text-third mx-2"
                        style="font-size: 24px;"
                        v-b-tooltip.hover
                        :title="$lang.Common.DataTable.ButtonEdit"
                      />
                    </div>
                  </td>
                  <td class="text-center">
                    <s v-if="release.incorrida === true">
                      {{ despesaTipoNome(release.despesaTipoID) }}
                    </s>
                    <span v-else>
                      {{ despesaTipoNome(release.despesaTipoID) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <s v-if="release.incorrida === true">
                      {{moment(release.dataDespesa).format('DD/MM/yyyy')}}
                    </s>
                    <span v-else>
                      {{moment(release.dataDespesa).format('DD/MM/yyyy')}}
                    </span>
                  </td>
                  <td class="text-center text-danger">
                    <div
                      style="min-width: 70px;"
                      :style="release.forma === 0 ? '' : 'color: #034EB4;'"
                    >
                      <s v-if="release.incorrida === true">
                        -{{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(release.valorTotal) }}
                      </s>
                      <span v-else>
                        {{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(release.valorTotal) }}
                      </span>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    style="color: #034EB4;"
                    :style="onGetBalance(index) < 0 ?  'color: #dc3545;' : 'color: #034EB4;'"
                  >
                    <s v-if="release.incorrida === true">
                      {{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(onGetBalance(index)) }}
                    </s>
                    <span v-else>
                      {{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(onGetBalance(index)) }}
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr style="background-color: #fafafa;">
                  <td colspan="4" class="text-right"><strong> Saldo Final </strong></td>
                  <td class="text-center" :class="onGetBalance(this.releases.length - 1) >= 0 ? 'text-success' : 'text-danger'"><strong>{{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(onGetBalance(this.releases.length - 1)) }}</strong></td>
                </tr>
              </tfoot>
            </table>
    </div>
    <div class="text-center py-2">
      <button
        type="button"
        :disabled="!despesa || !despesa.id"
        class="btn btn-third text-bold px-3 py-2"
        @click="onFinish"
      >
        <i class="fas fa-play-circle"/>&nbsp;{{ $lang.MiscExpensesReleases.ButtonFinish }}
      </button>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'MiscExpensesReleases',
  mixins: [
    ActionForms
  ],
  props: {
    despesa: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showReleases: true,
      showConfirm: false,
      obj: null,
      area: 1,
      releases: [],
      types: []
    }
  },
  watch: {
    'despesa.id': function (value) {
      this.onGetDespesaItens(value)
    }
  },
  mounted () {
    this.onGetDespesaItens(this.despesa.id)
    this.onGetDespesasTipos()
  },
  methods: {
    onAddExpense () {
      this.$router.push({ name: 'MiscExpensesType' })
    },
    onEditExpense (release) {
      this.$router.push({ name: 'MiscExpensesType', params: { edit: true, despesaItem: release } })
    },
    onDeleteExpense (release) {
      this.showReleases = false

      this.$nextTick(() => {
        this.showConfirm = true
        this.obj = release
      })
    },
    onCancelDeleteExpense () {
      this.showConfirm = false

      this.$nextTick(() => {
        this.showReleases = true
        this.obj = null
      })
    },
    onConfirmDeleteExpense () {
      this.showConfirm = false
      this.$database.businessCommon.despesaItemRepository.Delete(this.obj.id, () => {
        this.$database.businessCommon.despesaItemRepository.Sync(() => {
          this.onGetDespesaItens(this.despesa.id)
          this.onCancelDeleteExpense()
        }, () => {})
      }, (error) => {
        console.log(error)
      })
    },
    onFinish () {
      this.$router.push({ name: 'MiscExpensesConfirmation', params: { despesa: this.despesa } })

      // this.$database.businessCommon.despesaRepository.Upsert({
      //   ...this.despesa,
      //   dataAcao: this.moment().format('YYYY-MM-DD hh:mm:ss'),
      //   situacao: 1
      // }, success => {
      //   this.$database.businessCommon.despesaRepository.Sync(success => {
      //     console.log(success)
      //   }, error => {
      //     console.log(error)
      //   })

      //   this.toast.success(this.$lang.Common.Alerts.Success)
      //   this.$router.push({ name: 'MiscExpensesMenu' })
      // })
    },
    onGetDespesaItens (value) {
      if (value) {
        this.loading.push()
        this.$database.businessCommon.despesaItemRepository.GetAllByDespesa({ inquilinoID: this.accountLogged.InquilinoID, despesaID: value }, { sort: { dataDespesa: 1 } })
          .fetch(data => {
            this.releases = data
            this.loading.pop()
          }, error => {
            console.log(error)
            this.loading.pop()
          })
      }
    },
    onGetDespesasTipos () {
      this.$database.businessCommon.despesaTipoRepository.GetAllByDiversos({}, {})
        .fetch(data => {
          this.types = data
          this.loading.pop()
        }, error => {
          console.log(error)
          this.types = []
          this.loading.pop()
        })
    },
    onGetBalance (index) {
      const releases = this.releases

      let balance = 0

      for (let i = 0; i <= index; i++) {
        const release = releases[i]

        if (!release.incorrida) {
          if (release.forma === 1) {
            balance += release.valorTotal
          } else {
            balance -= release.valorTotal
          }
        }
      }

      return balance
    },
    despesaTipoNome (despesaTipoID) {
      return this.types.find(d => d.id === despesaTipoID).nome || 'N/D'
    }
  }
}
</script>
