import { mapGetters, mapActions } from 'vuex'
import SettingsModule, { MODULE_NAME } from './index'

const SettingsMixin = {
  namespaced: SettingsModule.namespaced,
  computed: { ...mapGetters(MODULE_NAME, Object.keys(SettingsModule.getters)) },
  methods: { ...mapActions(MODULE_NAME, Object.keys(SettingsModule.actions)) }
}

export default SettingsMixin
