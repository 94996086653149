import Vue from 'vue'
import App from './App.vue'
import wb from './registerServiceWorker'
import router from './router'
import store from './store'
import languages from './languages'

import Database from '@/database'

import Lang from 'vue-lang'

import VueTypeaheadBootstrap from '@/components/vue-typeahead-bootstrap/VueTypeaheadBootstrap.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import moment from 'moment'

import VeeValidateConfig from '@/shared/utils/veeValidate'
import VeeValidate from 'vee-validate'

import ToastrInstaller from './components/UI/toastr/Toastr'
import Guid from '@/shared/utils/guid'
import { toast, loading } from '@/shared/utils/UIHelper'
import { version } from '../package.json'

import SettingsMixin from '@/store/module/settings/mixin'
// import { JWTService } from '@/shared/services/jwt'

// PrimeVue ProgressBar
import PrimeVue from 'primevue/config'
import ProgressBar from 'primevue/progressbar'
import Sidebar from 'primevue/sidebar'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import AutoComplete from 'primevue/autocomplete'

// PrimeVue - import css
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'

// VueI18n
import VueI18n from 'vue-i18n'
import ptbr from './locales/ptbr.json'
import escl from './locales/escl.json'

require('./scss/common.scss')

Vue.config.productionTip = false

Vue.prototype.$workbox = wb

// Vue.prototype.accountLogged = JWTService.GetTokenPayload()
Vue.prototype.$database = Database

Vue.use(Lang, { lang: 'ptBr', locales: languages })

Vue.component('autocomplete', VueTypeaheadBootstrap)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueI18n)
Vue.use(VueTheMask)
Vue.use(money, { precision: 2 })
Vue.prototype.moment = moment

Vue.use(VeeValidate, VeeValidateConfig)

ToastrInstaller.Install()

Vue.prototype.guid = Guid
Vue.prototype.toast = toast
Vue.prototype.loading = loading
Vue.prototype.version = version

Vue.mixin(SettingsMixin)

// PrimeVue ProgressBar Component
Vue.use(PrimeVue)
Vue.component('ProgressBar', ProgressBar)
Vue.component('Sidebar', Sidebar)
Vue.component('InputText', InputText)
Vue.component('PDropdown', Dropdown)
Vue.component('AutoComplete', AutoComplete)

const numberFormats = {
  'pt-BR': {
    currency: {
      style: 'currency',
      currency: 'BRL'
    }
  },
  'es-CL': {
    currency: {
      style: 'currency',
      currency: 'CLP',
      currencyDisplay: 'symbol'
    }
  }
}
const i18n = new VueI18n({
  locale: 'ptbr',
  messages: {
    ptbr: ptbr,
    escl: escl
  },
  numberFormats
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
