import APIClient from '../../../../shared/http/clients/api'

const resourceName = 'despesasviagem'
const APIBusinessCommon = process.env.VUE_APP_BusinessCommon

const PostDespesa = (companyID, data = {}, config = {}) => APIClient.post(`${APIBusinessCommon}/${resourceName}/PostDespesa/${companyID}`, data, config)
const GetDespesa = (despesaId, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetDespesa/${despesaId}`, data, config)
const CheckIfUserCanSendExpenses = (id, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/CheckIfUserCanSendExpenses/${id}`, data, config)
const GetCategoriaEnum = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetCategoriaEnum`, data, config)
const GetEnumValorAdiantamento = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetEnumValorAdiantamento`, data, config)
const GetSubCategoriaEnum = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetSubCategoriaEnum`, data, config)
const GetSubCategoriaVeiculo = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetSubCategoriaVeiculo`, data, config)
const GetNotificacoes = (companyID, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetNotificacoes/${companyID}`, data, config)
const PutDespesas = (data = {}, config = {}) => APIClient.put(`${APIBusinessCommon}/${resourceName}/PutDespesas`, data, config)
const GetCategoria = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetCategoria`, data, config)
const GetTipoVeiculo = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetTipoVeiculo`, data, config)
const GetSubCategoria = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetSubCategoria`, data, config)
const GetFuncionarioByCodigo = (code, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetFuncionarioByCodigo/${code}`, data, config)
const GetFuncionarioByNome = (code, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetFuncionarioByNome/${code}`, data, config)
const GetAllDespesaEnviadas = (companyId, data = {}, config = {}) => APIClient.post(`${APIBusinessCommon}/${resourceName}/GetAllDespesaEnviadas/${companyId}`, data, config)
const SendAdiantamento = (valorAdiantamento, data = {}, config = {}) => APIClient.put(`${APIBusinessCommon}/${resourceName}/SendAdiantamento/${valorAdiantamento}`, data, config)
const GetFiltroDespesa = (companyId, filtro, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetFiltroDespesa/${companyId}/${filtro}`, data, config)
const GetFiltroDespesaHistorico = (categories, filtro, companyId, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetFiltroDespesaHistorico/${filtro}/${companyId}/${categories}`, data, config)
const VerificaAdiantamento = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/VerificaAdiantamento`, data, config)
const VerificarUsuarioAdiantamento = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/VerificarUsuarioAdiantamento`, data, config)
const GetAnexoById = (despesasId, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/DownloadZpagConprovante/${despesasId}`, data, config)
const GetDespesasAprovadas = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetDespesasAprovadas`, data, config)

const SeveralExpansesRepository = {
  PostDespesa,
  GetDespesa,
  GetCategoriaEnum,
  GetEnumValorAdiantamento,
  GetSubCategoriaEnum,
  GetSubCategoriaVeiculo,
  GetNotificacoes,
  PutDespesas,
  GetCategoria,
  GetTipoVeiculo,
  GetSubCategoria,
  GetFuncionarioByCodigo,
  GetFuncionarioByNome,
  GetAllDespesaEnviadas,
  SendAdiantamento,
  GetFiltroDespesa,
  GetFiltroDespesaHistorico,
  VerificaAdiantamento,
  VerificarUsuarioAdiantamento,
  CheckIfUserCanSendExpenses,
  GetAnexoById,
  GetDespesasAprovadas
}

export default SeveralExpansesRepository
