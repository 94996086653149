export default {
  name: 'DatabaseMixin',
  data () {
    return {
      coreConnected: false,
      businessCommonConnected: false
    }
  },
  computed: {
    coreConnection: function () {
      return window.indexedDB.open('coredb')
    },
    businessCommonConnection: function () {
      return window.indexedDB.open('businesscommondb')
    },
    isConnected: function () {
      return this.coreConnected && this.businessCommonConnected
    }
  },
  beforeMount () {
    this.beforeLoad()
  },
  methods: {
    beforeLoad () {
      const connectionEvent = this.onDatabaseConnect

      this.coreConnection.onsuccess = function () {
        connectionEvent('coreConnected')
      }

      this.businessCommonConnection.onsuccess = function () {
        connectionEvent('businessCommonConnected')
      }
    },
    onDatabaseConnect (event) {
      this[event] = true
    }
  }
}
