<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.ReimbursementClosings.LabelTitle }}</span>
    </div>
    <div class="w-100 overflow-x-auto flex-grow-1">
      <table class="table table-hover" style="border: 1px solid #dcdede;">
              <thead class="thead-third" style="border: solid 1px #0277bd; height: 44px;">
                <tr>
                  <th class="text-center">{{$lang.ReimbursementClosings.DataTable.Board}}</th>
                  <th class="text-center">{{$lang.ReimbursementClosings.DataTable.SendDate}}</th>
                  <th class="text-center">{{$lang.ReimbursementClosings.DataTable.Value}}</th>
                  <th class="text-center">{{$lang.ReimbursementClosings.DataTable.Status}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="closings.length === 0">
                  <td colspan="4" class="text-center">
                    {{$lang.Common.DataTable.NoRecordsFound}}
                  </td>
                </tr>
                <tr v-else v-for="closing in closings" v-bind:key="closing.id">
                  <td class="text-center">
                    {{ closing.folha }}
                  </td>
                  <td class="text-center">
                    {{moment(closing.dataAcao).format('DD/MM/yyyy')}}
                  </td>
                  <td class="text-center">
                    {{new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(closing.valorTotal)}}
                  </td>
                  <td class="text-center text-bold">
                    <div v-if="closing.situacao < 0" class="text-danger">{{ $lang.ReimbursementClosings.Status.Disapproved }}</div>
                    <div v-if="closing.situacao > 0 && closing.situacao < 4" class="text-warning">{{ $lang.ReimbursementClosings.Status.WaitingApproval }}</div>
                    <div v-if="closing.situacao === 4" class="text-info">{{ $lang.ReimbursementClosings.Status.Approved }}</div>
                    <div v-if="closing.situacao === 5" class="text-success">{{ $lang.ReimbursementClosings.Status.Payed }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
    </div>
    <div class="text-center py-2 mb-4">
      <button
        type="button"
        class="btn btn-third text-bold px-4 py-2"
        @click="onBack"
      >
        <i class="fas fa-arrow-circle-left mr-1"/>&nbsp;{{ $lang.ReimbursementClosings.ButtonBack }}
      </button>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'Reimbursement',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      closings: []
    }
  },
  mounted () {
    this.onGetClosings()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'ReimbursementMenu' })
    },
    onGetClosings () {
      this.loading.push()
      this.$database.businessCommon.despesaRepository.GetFechamentos({ inquilinoID: this.accountLogged.InquilinoID, contaCriacaoID: this.accountLogged.ContaID, situacao: { $not: 0 }, area: 3 }, {})
        .fetch(data => {
          this.closings = data
          this.loading.pop()
        }, error => {
          console.log(error)
          this.closings = []
          this.loading.pop()
        })
    }
  }
}
</script>
