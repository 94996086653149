<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.MiscExpensesAttach.LabelTitle }}</span>
    </div>
    <div class="card w-100 mb-1">
      <div class="card-body d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.MiscExpensesAttach.LabelAttach }}</div>
        <div class="text-center mt-2">
          <label>
            <b-form-file
              v-show="false"
              v-model="selectedAttachs"
              multiple
              ref="file"
              accept="image/*"
              @input="onFileChanged"
            />
            <button type="file" class="btn btn-third text-bold mx-1" @click="$refs.file.$el.click()"><i class="fas fa-camera"/>&nbsp;{{ $lang.MiscExpensesAttach.ButtonAdd }}</button>
          </label>
          <button
            type="button"
            class="btn btn-third text-bold mx-1"
            @click="onFinish"
          >
            <i class="fas fa-check-circle"/>&nbsp;{{ $lang.MiscExpensesAttach.ButtonFinish }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="attachs && Array.isArray(attachs)"
      v-for="(file, index) in attachs"
      :key="file.name"
      class="w-100 mb-1"
    >
      <div class="card w-100" style="height: 170px;">
        <div class="card-body d-flex flex-column align-items-center justify-content-center p-0 m-0" style="color: rgba(0,0,0, 0.1);">
          <img :src="url[index]" width="100%" height="170px" style="border-radius: 0.25rem;" />
        </div>
      </div>
      <button
        type="button"
        class="btn btn-third text-bold mt-1 w-100"
        @click="onRemoveFile(file, index)"
      >
        <i class="fas fa-trash-alt"/>&nbsp;{{ $lang.MiscExpensesAttach.ButtonRemove }}
      </button>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'MiscExpensesAttach',
  mixins: [
    ActionForms
  ],
  props: {
    despesa: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedAttachs: [],
      attachs: []
    }
  },
  computed: {
    isEditing: function () {
      return this.$route.params && this.$route.params.edit
    },
    back: function () {
      return this.$route.params && this.$route.params.back && this.$route.params.back === true
    },
    editingRelease: function () {
      return this.$route.params.despesaItem
    },
    url: function () {
      const urls = []

      for (const file of this.attachs) {
        if (file && file.type.includes('image')) {
          const binaryData = []
          binaryData.push(file)
          urls.push(URL.createObjectURL(new Blob(binaryData, { type: file.type })))
        }
      }

      return urls
    }
  },
  methods: {
    onLoad () {
      if (this.isEditing) {
        this.attachs = this.editingRelease.arquivo || []
      } else if (this.back) {
        this.attachs = this.$route.params.attachs
      }
    },
    onFileChanged (event) {
      for (const file of event) {
        const hasFile = this.attachs.find(x => x.name === file.name)

        if (!hasFile) {
          this.attachs.push(file)
        }
      }
    },
    onRemoveFile (file, index) {
      this.loading.push()
      this.attachs.splice(index, 1)

      this.$nextTick(() => {
        this.loading.pop()
      })
    },
    onFinish () {
      this.loading.push()

      try {
        const id = this.isEditing ? this.editingRelease.id : this.guid.New()
        const params = {
          ...this.$route.params,
          quantity: parseInt(this.$route.params.quantity)
        }

        const data = {
          id: id,
          _id: id,
          inquilinoID: this.accountLogged.InquilinoID,
          despesaID: this.despesa.id,
          despesaTipo: params.type,
          despesaTipoID: params.type.id,
          quantidade: params.quantity,
          valorUnitario: params.value,
          valorTotal: params.quantity * params.value,
          dataDespesa: params.date,
          situacaoContaID: null,
          observacao: params.comments,
          arquivo: this.attachs,
          forma: 0,
          situacao: 0,
          status: 1,
          dataAcao: new Date(),
          ContaIDAcao: this.accountLogged.AccountID
        }

        this.$database.businessCommon.despesaItemRepository.Create(data, () => {
          this.toast.success(this.$lang.Common.Alerts.Success)

          this.$database.businessCommon.despesaItemRepository.Sync(() => {
            this.loading.pop()
            this.$router.push({ name: 'MiscExpensesReleases' })
          }, error => {
            console.log(error)
            this.loading.pop()
          })
        })
      } catch {
        this.loading.pop()
      }
    }
  }
}
</script>
