import { database } from '../config'

let LocalDB
const resourceName = 'termoaceite'

const Create = (
  data = {},
  func = () => {}
) => database('', resourceName, LocalDB)[resourceName].upsert(data, func)

const Sync = (success, error) =>
  database(
    '',
    resourceName,
    LocalDB,
    data => {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('doc')) {
        data = data.doc
      }
      return data
    }
  )[resourceName].upload(success, error)

const TermoAceiteRepository = (localDB) => {
  LocalDB = localDB
  return {
    Create,
    Sync
  }
}

export default TermoAceiteRepository
