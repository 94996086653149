import PaisRepository from './repository/pais'
import EstadoRepository from './repository/estado'
import CidadeRepository from './repository/cidade'
import ContaRepository from './repository/conta'

export default (localDB) => {
  return {
    contaRepository: ContaRepository(localDB),
    paisRepository: PaisRepository(localDB),
    estadoRepository: EstadoRepository(localDB),
    cidadeRepository: CidadeRepository(localDB)
  }
}
