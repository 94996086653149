<template>
  <div class="app h-100">
    <AppHeader ref="header" />
    <main class="h-100 d-flex flex-column">
      <div style="height: 76px;"/>
      <div class="text-center w-100 py-2 px-4" style="background-color: #BED9B7; !important">
        <span class="text-bold" style="color: #285C0A;">{{ $lang.StartPage.LabelCompany }}: {{ currentCompany }}</span>
        <div class="d-flex align-items-center pt-1">
          <span style="color: #285C0A;">Obra:</span>
          <b-form-select
            id="despesa"
            name="despesa"
            :disabled="$route.meta && $route.meta.hasOwnProperty('obraRhEnabled') && $route.meta.obraRhEnabled === false"
            v-model="despesa.value"
            :options="despesa.options"
            data-vv-as="despesa"
            v-validate="'required'"
            aria-describedby="obraIDErrorFeedback"
          >
          <template #first>
            <option :value="{}" disabled>
              {{ $lang.Common.Select }}
            </option>
          </template>
        </b-form-select>
        </div>
      </div>
      <div class="container-fluid flex-grow-1">
        <main class="h-100 d-flex flex-column pb-2">
          <transition name="fade">
            <router-view :despesa="despesa.value"/>
          </transition>
        </main>
      </div>
    </main>
    <AppFooter ref="footer" style="display: none;" />
  </div>
</template>

<script>
import AppHeader from './layouts/Header'
import AppFooter from './layouts/Footer'

export default {
  name: 'SecondLayout',
  components: {
    AppHeader,
    AppFooter
  },
  data () {
    return {
      despesa: {
        value: {},
        options: []
      }
    }
  },
  computed: {
    currentCompany: function () {
      return `${this.company.zCode} - ${this.company.name}`
    }
  },
  mounted () {
    this.onGetObra()
  },
  methods: {
    onGetObra () {
      this.loading.push()
      this.$database.businessCommon.despesaRepository.GetAllByAreaDespesa({ inquilinoID: this.accountLogged.InquilinoID, contaCriacaoID: this.accountLogged.ContaID, situacao: 0, area: this.$route.meta.area }, {})
        .fetch(data => {
          this.despesa.options = data.map(despesa => {
            return {
              value: despesa,
              text: despesa.obraRH.nome
            }
          })

          this.loading.pop()
        }, error => {
          console.error(error)
          this.loading.pop()
        })
    }
  }
}
</script>
