import { database } from '../config'

let LocalDB
const resourceName = 'estado'

const GetAll = (
  selector = {},
  options = {}
) => database(`pais/${selector.paisID}`, resourceName, LocalDB)[resourceName].find(selector, options)

const EstadoRepository = (localDB) => {
  LocalDB = localDB
  return {
    GetAll
  }
}

export default EstadoRepository
