<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.ReimbursementAuthorization.LabelTitle }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold text-center">{{ $lang.ReimbursementAuthorization.Responsable.LabelDescription }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-input
            id="code"
            name="code"
            type="number"
            placeholder="Código"
            v-model.number="responsable.value"
            data-vv-as="code"
            v-validate="'required'"
            aria-describedby="codeErrorFeedback"
            @blur="onChangeResponsable"
            />
          <b-form-input
            id="name"
            name="name"
            placeholder="Nome"
            class="mt-3"
            v-model="responsable.name"
            readonly
          />
        </b-card-text>
        <div class="text-center align-self-end">
          <button
            type="button"
            class="btn btn-third text-bold"
            @click="onAdd"
          >
            <i class="fas fa-plus-circle"/>&nbsp;{{ $lang.ReimbursementAuthorization.Responsable.ButtonAdd }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'ReimbursementAuthorizationResponsable',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      responsable: {
        id: null,
        value: null,
        name: null,
        options: []
      }
    }
  },
  methods: {
    onAdd () {
      if (this.responsable.id && this.responsable.value && this.responsable.name) {
        this.$router.push({ name: 'ReimbursementAuthorizationFavored', params: { ...this.$route.params, responsavelID: this.responsable.id } })
      } else {
        this.toast.error(this.$lang.ReimbursementAuthorization.Responsable.Errors.ResponsableRequired)
      }
    },
    onChangeResponsable () {
      if (this.responsable.value) {
        this.loading.push()

        this.$database.businessCommon.funcionarioRepository.GetResponsibleByCode({ codigo: parseInt(this.responsable.value) }, {},
          obj => {
            if (obj) {
              this.responsable.id = obj.id
              this.responsable.name = obj.nome
            } else {
              this.responsable.id = null
              this.responsable.name = null
            }
            this.loading.pop()
          }, error => {
            this.responsable.id = null
            this.responsable.name = null
            console.log(error)
            this.loading.pop()
          })
      } else {
        this.responsable.id = null
        this.responsable.name = null
      }
    }
  }
}
</script>
