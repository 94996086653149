import profileExpensesRepository from '../../../shared/http/repositories/Business/profileExpenses'
export default {
  name: 'Profile',
  components: {
  },
  data () {
    return {
      nome: null,
      codigo: null,
      funcao: null,
      selected: null,
      cookie: null
    }
  },
  mounted () {
    this.getInfoPerfil()
  },
  methods: {
    VoltarConsultas () {
      this.$router.push('/consultas')
    },
    getInfoPerfil () {
      profileExpensesRepository.GetPerfil()
        .then(Response => {
          console.log(Response)
          this.nome = Response.data[0].nome
          this.codigo = Response.data[0].codigo
          this.funcao = Response.data[0].funcao
        })
    }
  }
}
