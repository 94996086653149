<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.ReimbursementAuthorization.LabelTitle }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.ReimbursementAuthorization.Term.LabelDescription }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-textarea
            id="term"
            name="term"
            v-model="term"
            required
            readonly
            rows="10"
            no-resize
          />
        </b-card-text>
        <b-form-checkbox
          class="align-self-start"
          v-model="accept"
        >
          {{ $lang.ReimbursementAuthorization.Term.CheckBoxAgree }}
        </b-form-checkbox>
        <div class="text-center align-self-end">
          <button
            type="button"
            class="btn btn-third text-bold"
            @click="onFinish"
          >
            <i class="fas fa-check-circle"/>&nbsp;{{ $lang.ReimbursementAuthorization.Term.ButtonFinish }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

import TermType from '@/shared/utils/termtype.js'

export default {
  name: 'ReimbursementAuthorizationTerm',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      accept: false,
      term: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dignissim tempus massa, vitae dictum mauris mollis at. Ut augue augue, ultricies in tincidunt non, convallis ac odio. Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut egestas metus sit amet mauris volutpat interdum. Sed lacinia nunc in rhoncus pellentesque. Vivamus nec consectetur diam. Nam bibendum ac leo a cursus. Nam porttitor justo non elit aliquam, quis hendrerit est accumsan. Integer egestas eget mi nec placerat. Vestibulum euismod lacus ligula, vitae interdum sapien ultricies mattis. Sed ut vehicula tellus, vel dictum est. Fusce dictum consequat justo ut fringilla. Nam ornare libero ac sollicitudin posuere. Duis eget blandit magna, vel fringilla dolor.'
    }
  },
  methods: {
    finished () {
      this.$router.push({ name: 'ReimbursementMenu' })
    },
    onFinish () {
      if (this.accept) {
        try {
          const id = this.guid.New()
          const data = {
            id: id,
            _id: id,
            inquilinoID: this.accountLogged.InquilinoID,
            empresaID: this.company.companyID,
            tipoTermo: TermType.ReimbursementELLANEOUS_EXPENSES,
            solicitanteID: this.accountLogged.ContaID,
            dataSolicitacao: this.moment().format('YYYY-MM-DDThh:mm:ss'),
            dataAcao: this.moment().format('YYYY-MM-DDThh:mm:ss'),
            situacao: 0,
            ...this.$route.params
          }

          this.$database.businessCommon.termoAceiteRepository.Create(data, obj => {
            if (obj) {
              this.toast.success(this.$lang.Common.Alerts.Success)

              this.$database.businessCommon.termoAceiteRepository.Sync(success => {
                console.log(success)
                this.loading.pop()
              }, error => {
                console.log(error)
                this.loading.pop()
              })
              this.$router.push({ name: 'ReimbursementMenu' })
            }
          })

          // this.$database.businessCommon.termoAceiteRepository.Sync(success => {
          //   console.log(success)
          //   this.loading.pop()
          // }, error => {
          //   console.log(error)
          //   this.loading.pop()
          // })
        } catch {
          this.loading.pop()
        }
      } else {
        this.toast.error(this.$lang.ReimbursementAuthorization.Term.Errors.NeedAccept)
      }
    }
  }
}
</script>
