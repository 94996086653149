<template>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.MiscExpensesMenu.LabelTitle }}</span>
    </div>
    <div class="card bg-third border-third mt-3 pointer">
      <div
        class="card-body text-bold text-center d-flex align-items-center justify-content-center"
        style="width: 80vw; min-height: 90px;"
        @click="onGoToMiscExpensesReleases"
      >
        <i class="fas fa-cart-plus" style="font-size: 32px;"/>
        <span class="ml-3" style="font-size: 22px;">{{ $lang.MiscExpensesMenu.LabelReleases }}</span>
      </div>
    </div>
    <div class="card bg-third border-third mt-3 pointer">
      <div
        class="card-body text-bold text-center d-flex align-items-center justify-content-center"
        style="width: 80vw; min-height: 90px;"
        @click="onGoToMiscExpensesClosings"
      >
        <div class="d-flex align-items-center justify-content-center">
          <i class="fas fa-file-alt" style="font-size: 32px;"/>
          <span class="ml-3" style="font-size: 22px;">{{ $lang.MiscExpensesMenu.LabelClosings }}</span>
        </div>
      </div>
    </div>
    <!-- <div class="card bg-third border-third mt-3 pointer">
      <div
        class="card-body text-bold text-center d-flex align-items-center justify-content-center"
        style="width: 80vw; min-height: 90px;"
        @click="onGoToMiscAuthorization"
      >
        <div class="d-flex align-items-center justify-content-center">
          <i class="fas fa-file-signature" style="font-size: 32px;"/>
          <span class="ml-3" style="font-size: 22px;">{{ $lang.MiscExpensesMenu.LabelAuthorization }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'MiscExpensesMenu',
  methods: {
    onGoToMiscExpensesReleases () {
      this.$router.push({ name: 'MiscExpensesReleases' })
    },
    onGoToMiscExpensesClosings () {
      this.$router.push({ name: 'MiscExpensesClosings' })
    },
    onGoToMiscAuthorization () {
      this.$router.push({ name: 'MiscAuthorizationConstruction' })
    }
  }
}
</script>
