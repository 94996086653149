import { isEmpty } from 'lodash'

export const getters = {
  account: state => state.account,
  accounts: state => state.accounts,
  isRegulator: (state, getters, rootState) => {
    const account = getters.account
    const hasData = !isEmpty(account)

    if (!hasData) {
      return false
    }

    const isRegulator = !isEmpty(account.expertID)
    return isRegulator
  }
}

export default getters
