import { mutationTypes as mutation } from './mutations'
import { JWTService } from '@/shared/services/jwt'

const SET_ACCOUNT_LOGGED = 'setAccountLogged'
const GET_COMPANY = 'getCompany'
const SET_COMPANY = 'setCompany'
const SET_TYPEEXPENSES = 'settypeexpenses'
const GET_TYPEEXPENSES = 'getyypeexpenses'
const CLEAR_COMPANY = 'clearComapny'

export const actionTypes = {
  SET_ACCOUNT_LOGGED,
  SET_COMPANY,
  CLEAR_COMPANY,
  SET_TYPEEXPENSES
}

export const globalActions = {
  SET_ACCOUNT_LOGGED: `settings/${SET_ACCOUNT_LOGGED}`,
  SET_COMPANY: `settings/${SET_COMPANY}`,
  SET_TYPE_EXPENSES: `settings/${SET_TYPEEXPENSES}`,
  CLEAR_COMPANY: `settings/${CLEAR_COMPANY}`
}

export const actions = {
  [SET_ACCOUNT_LOGGED] ({ commit }) {
    commit(mutation.SET_ACCOUNT_LOGGED, JWTService.GetTokenPayload())
  },
  async [GET_COMPANY] ({ state, commit }) {
    let company = localStorage.getItem('company')

    if (company) {
      company = JSON.parse(company)
      await commit(mutation.SET_COMPANY, company)

      return company
    } else {
      return state.company
    }
  },
  [SET_COMPANY] ({ commit }, payload) {
    commit(mutation.SET_COMPANY, payload)
  },
  [CLEAR_COMPANY] ({ commit }) {
    commit(mutation.CLEAR_COMPANY)
  },
  [SET_TYPEEXPENSES] ({ commit }, payload) {
    commit(mutation.SET_TYPEEXPENSES, payload)
  },
  async [GET_TYPEEXPENSES] ({ state, commit }) {
    var CryptoJS = require('crypto-js')
    let typeExpenses = localStorage.getItem('typeExpenses')
    if (typeExpenses) {
      typeExpenses = CryptoJS.AES.decrypt(typeExpenses, 'z@pMvoe')
      typeExpenses = JSON.parse(typeExpenses.toString(CryptoJS.enc.Utf8))
      await commit(mutation.SET_TYPEEXPENSES, typeExpenses.typeExpenses)

      return typeExpenses.typeExpenses
    } else {
      return state.typeExpenses
    }
  }
}
