import { database } from '../config'

let LocalDB
const resourceName = 'secaorh'

const GetAll = (
  selector = {},
  options = {}
) => database(`empresa/${selector.empresaID}`, resourceName, LocalDB)[resourceName].find(selector, options)

const SecaoRHRepository = (localDB) => {
  LocalDB = localDB
  return {
    GetAll
  }
}

export default SecaoRHRepository
