<template>
  <label class="file-select">
    <div class="select-button">
      <span class="selecionado" v-if="value">{{value.name}}</span>
      <span class="nao-selecionado" v-else>{{ $t('add_expenses.describeAttach') }}</span>
    </div>
    <input type="file" size="10485760" @change="handleFileChange"  id="id"/>
    <div id="imagemContainer"></div>
  </label>
</template>

<script>
import { toast } from '@/shared/utils/UIHelper'

export default {
  props: {
    value: File
  },

  methods: {
    handleFileChange (e) {
      if (e.target.files[0].size <= 10485760) {
        this.$emit('edicao', true)
        this.$emit('input', e.target.files[0])
      } else {
        toast.error('Arquivo deve ser menor que 10mb!!')
      }
    }
  }
}
</script>

<style scoped>
.file-select > .select-button .selecionado{
    padding: 0.6rem 7.4rem;
    background-color: white;
    border-width: 2px;
    border-radius: 8px;
    text-align: center;
    color: #2EA169;
    border-color: #2EA169;
    border-style: solid;
}

.file-select > .select-button .nao-selecionado{
  padding: 0.6rem 5.9rem;
  background-color: white;
  border-width: 2px;
  border-radius: 8px;
  text-align: center;
  color: #495057;
  border-color: #ced4da;
  border-style: dashed;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
