<template>
  <div class="app h-100">
    <AppHeader ref="header" />
    <main class="h-100 d-flex flex-column">
      <div class="container-fluid flex-grow-1">
        <main class="h-100 d-flex flex-column pb-2">
          <div class="text-third text-center" style="margin-bottom: 5%">
             <!-- {{ $lang.StartPage.LabelCompany }}: {{ currentCompany }} -->
          </div>
          <transition name="fade">
            <router-view/>
          </transition>
        </main>
      </div>
    </main>
    <AppFooter ref="footer" style="display: none;" />
  </div>
</template>

<script>
import AppHeader from './layouts/Header'
import AppFooter from './layouts/Footer'

export default {
  name: 'PrivateLayout',
  components: {
    AppHeader,
    AppFooter
  },
  computed: {
    currentCompany: function () {
      return `${this.company.zCode} - ${this.company.name}`
    }
  }
}
</script>
