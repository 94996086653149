<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.TravelExpensesAttach.LabelTitle }}</span>
    </div>
    <div class="card w-100 mb-3">
      <div class="card-body d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.TravelExpensesAttach.LabelAttach }}</div>
        <div class="text-center mt-2">
          <label>
            <b-form-file
              v-show="false"
              v-model="attach"
              ref="file"
              accept="image/*"
              @change="onFileChange"
            />
            <button type="file" class="btn btn-third text-bold mx-1" @click="$refs.file.$el.click()"><i class="fas fa-camera"/>&nbsp;{{ $lang.TravelExpensesAttach.ButtonAdd }}</button>
          </label>
          <button
            type="button"
            class="btn btn-third text-bold mx-1"
            @click="onFinish"
          >
            <i class="fas fa-check-circle"/>&nbsp;{{ $lang.TravelExpensesAttach.ButtonFinish }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="attach && !Array.isArray(attach)" class="w-100">
      <div class="card w-100" style="height: 170px;">
        <div class="card-body d-flex flex-column align-items-center justify-content-center p-0 m-0" style="color: rgba(0,0,0, 0.1);">
          <img :src="this.url" width="100%" height="170px" style="border-radius: 0.25rem;" />
        </div>
      </div>
      <button
        type="button"
        class="btn btn-third text-bold mt-1 w-100"
        @click="onClearFile"
      >
        <i class="fas fa-trash-alt"/>&nbsp;{{ $lang.TravelExpensesAttach.ButtonRemove }}
      </button>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'TravelExpensesAttach',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      attach: [],
      url: null
    }
  },
  methods: {
    onFileChange (event) {
      this.loading.push()
      const file = event.target.files[0]

      if (file && file.type.includes('image')) {
        const binaryData = []
        binaryData.push(file)
        this.url = URL.createObjectURL(new Blob(binaryData, { type: file.type }))
      } else {
        this.onClearFile()
      }

      this.$nextTick(() => {
        this.loading.pop()
      })
    },
    onClearFile () {
      this.loading.push()
      this.attach = []
      this.url = null

      this.$nextTick(() => {
        this.loading.pop()
      })
    },
    onFinish () {
      this.loading.push()

      try {
        const id = this.guid.New()
        const params = {
          ...this.$route.params,
          quantity: parseInt(this.$route.params.quantity)
        }

        const data = {
          id: id,
          _id: id,
          inquilinoID: this.accountLogged.InquilinoID,
          despesaID: params.despesa.id,
          despesaTipoID: params.type.id,
          quantidade: params.quantity,
          valorUnitario: params.value,
          valorTotal: params.quantity * params.value,
          dataDespesa: params.date,
          situacaoContaID: null,
          observacao: '',
          arquivo: this.attach,
          forma: 0,
          situacao: 0,
          status: 1,
          dataAcao: new Date(),
          ContaIDAcao: this.accountLogged.AccountID
        }

        this.$database.businessCommon.despesaItemRepository.Create(data, () => {
          this.toast.success(this.$lang.Common.Alerts.Success)

          this.$database.businessCommon.despesaItemRepository.Sync(() => {
            this.loading.pop()
            this.$router.push({ name: 'TravelExpensesReleases' })
          }, error => {
            console.log(error)
            this.loading.pop()
          })
        })
      } catch {
        this.loading.pop()
      }
    }
  }
}
</script>
