import router from '@/router'
import { JWTService } from '@/shared/services/jwt'
import ClientFactory from '@/shared/factories/client'

const HttpClient = (endPoint, method = '', url, params = {}, sendData = {}, success = () => {}, error = () => {}) => {
  const client = {
    baseURL: url || '',
    headers: { 'Content-Type': 'multipart/form-data' }
  }

  const APIClient = ClientFactory.Create(client)

  APIClient.interceptors.request.use((request) => {
    const token = JWTService.GetToken()

    if (token) request.headers.Authorization = `Bearer ${JWTService.GetToken()}`

    return request
  }, error => Promise.reject(error))

  APIClient.interceptors.response.use(response => response, (error) => {
    if (error.response.status.toString().match(/4\d[13]/)) {
      JWTService.DestroyToken()
      router.replace({ name: 'SignIn' })
    }
    return Promise.reject(error)
  })

  method = method.toLowerCase()

  if (method === 'patch') method = 'put'
  else if (method === 'delete') endPoint = endPoint.replace('upload', '')

  APIClient[method](endPoint, sendData)
    .then(res => {
      let { data } = res.data

      if (data) {
        if (Array.isArray(data)) {
          data = data.map(x => {
            return {
              ...x,
              _id: x.id
            }
          })
        } else {
          if (method === 'put' || method === 'post') {
            data = {
              ...data,
              _id: data.id
            }
          } else {
            data = [{
              ...data,
              _id: data.id
            }]
          }
        }

        success(data)
      } else {
        error(data)
      }
    })
    .catch(err => {
      error(err)
    })
}

export default HttpClient
