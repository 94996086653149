/* eslint-disable no-prototype-builtins */
import Tools from '@/components/tools/Tools.js'
// import Alerts from '@/components/forms/Alerts'
import { mask } from 'vue-the-mask'
// import Forms from '@/components/forms/Forms'
import Tables from '@/components/forms/Tables'
import TableColumn from '@/components/forms/TableColumn'
import { JWTService } from '@/shared/services/jwt'

export default {
  name: 'actionForms',
  components: {
    Tables,
    TableColumn
  },
  data () {
    return {
      data: null,
      form: null,
      objList: null,
      error: null,
      polling: null
    }
  },
  directives: { mask },
  created () {
  },
  mounted () {
    Tools.MinimizedSidebar()
    this.onClear()
    this.onLoad()
  },
  methods: {
    onClear () {
      Tools.ClearDataForms(this.form, this.data)
      if (this.$refs.alert) {
        this.$refs.alert.Close()
      }
    },
    setFormData (data) {
      Tools.SetValueInForm(this.form, this.data, data)
    },
    onEdit (obj) {
      this.loading.push()
      Tools.SetValueInForm(this.form, this.data, obj)
      this.loading.pop()
    },
    onSave () {
      var self = this
      if (Tools.ValidateForms(this.form, this.data, self, this.toast)) {
        // Set default data
        this.data.dateUpdate = this.moment().format('YYYY-MM-DD hh:mm:ss')
        this.data.accountIDUpdate = JWTService.GetTokenPayload().AccountID
        if (this.data.hasOwnProperty('companyID')) {
          this.data.companyID = JWTService.GetTokenPayload().companyID
        }

        if (this.data) {
          if (this.data.id !== null && this.data.id !== '00000000-0000-0000-0000-000000000000') {
            this.onUpdate()
          } else {
            this.data.id = '00000000-0000-0000-0000-000000000000'
            this.data.status = 1
            this.onCreate()
          }
        } else {
          // this.toast.error(this.$lang.Common.NoRecordInformed)
        }
      }
    },
    onSearch () {
      console.log('Warning: Override method in your view implementation onSearch()')
    },
    onLoad () {
      console.log('Warning: Override method in your view implementation onLoad()')
    },
    onCreate () {
      console.log('Warning: Override method in your view implementation onCreate()')
    },
    onUpdate () {
      console.log('Warning: Override method in your view implementation onUpdate()')
    },
    onChange (obj) {
      for (var key in this.data) {
        if (obj.hasOwnProperty(key)) {
          this.data[key] = obj[key]
        }
      }
      if (this.data.status === true) {
        this.data.status = false
      } else {
        this.data.status = true
      }
      this.onUpdate()
    },
    onResultObj (response) {
      if (response.data.code >= 0) {
        return response.data.data
      } else {
        this.toast.error(this.$lang.Common.CouldNotLoadRegister)
        return null
      }
    },
    onResultSimple (response) {
      this.onResult(response, true, true)
    },
    onResult (response, IsNotLoad, IsNotClear) {
      if (response.data.code >= 0) {
        if (!IsNotClear) {
          this.onClear()
        }
        this.$refs.alert.ShowSuccess()
        if (!IsNotLoad) {
          this.onLoad()
        }
      } else {
        this.$refs.alert.ShowError()
      }
      this.loading.pop()
    },
    onResultGrid (response, tableGridRef) {
      if (response.data.code >= 0) {
        this.objList = response.data.data
        if (tableGridRef) {
          tableGridRef.LoadAllByData(response)
        } else {
          this.$refs.tableGrid.LoadAllByData(response)
        }
      } else {
        this.toast.error(this.$lang.Common.CouldNotLoadRegister)
      }
      this.loading.pop()
    },
    onStartTotalGrid (tableGridRef, total) {
      tableGridRef.LoadStartTotalGrid(total)
    },
    onException (err) {
      console.log(err)
      if (err.message === 'Unauthorized') {
        this.toast.error(this.$lang.Common.SessionEnded)
      } else {
        this.toast.error(this.$lang.Common.MicroserviceUnavailable)
      }
      this.loading.pop()
    },
    onResultDropdown (response, hasOpt) {
      var result = []
      if (response.data.code >= 0) {
        var data = response.data.data
        for (var i in data) {
          if (data[i].status) {
            if (hasOpt) {
              result.push({ text: ' &nbsp;&nbsp; ' + data[i].name, value: data[i].id })
            } else {
              result.push({ text: data[i].name, value: data[i].id })
            }
          } else {
            result.push({ text: data[i].name, value: data[i].id, disabled: true })
          }
        }
      } else {
        this.toast.error(this.$lang.Common.CouldNotLoadRegister)
      }
      this.loading.pop()

      return result
    },
    onSetDropdown (data, hasOpt) {
      var result = []

      if (data && data.length > 0) {
        for (var i in data) {
          if (data[i].status) {
            if (hasOpt) {
              result.push({ text: ' &nbsp;&nbsp; ' + data[i].name, value: data[i].id })
            } else {
              result.push({ text: data[i].name, value: data[i].id })
            }
          } else {
            result.push({ text: data[i].name, value: data[i].id, disabled: true })
          }
        }
      }

      return result
    },
    onResultSelect2 (response) {
      var result = []
      if (response.data.code >= 0) {
        var data = response.data.data
        for (var i in data) {
          if (data[i].status) {
            result.push({ label: data[i].name, value: data[i].id })
          }
        }
      } else {
        this.toast.error(this.$lang.Common.CouldNotLoadRegister)
      }
      this.loading.pop()

      return result
    },
    onResultAutoComplete (response) {
      var result = []
      if (response.data.code >= 0) {
        var data = response.data.data
        for (var i in data) {
          if (data[i].alias) {
            result.push({ text: data[i].alias, value: data[i] })
          } else {
            result.push({ text: data[i].name, value: data[i] })
          }
        }
      } else {
        this.toast.error(this.$lang.Common.CouldNotLoadRegister)
      }
      this.loading.pop()

      return result
    },
    disableButton (id) {
      const button = document.getElementById(id)

      if (button) {
        button.classList.add('disabled')
        button.disabled = true
      }
    },
    enableButton (id) {
      const button = document.getElementById(id)

      if (button) {
        button.classList.remove('disabled')
        button.disabled = false
      }
    },
    getValidationState (fieldName) {
      const field = this.$validator.fields.find({ name: fieldName })

      if (!field) return null

      const isDirty = field.flags.dirty
      const isValid = !this.errors.has(fieldName)

      return isDirty ? isValid : null
    }
  }
}
