import { database, upload } from '../config'

let LocalDB
const resourceName = 'despesaitem'

const toFormData = data => {
  if (data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('doc')) {
      if (data.doc.dataAcao !== data.base.dataAcao) {
        data = data.doc
      } else {
        return data
      }
    }

    const formData = new FormData()

    for (const file of data.arquivo) {
      formData.append('files', file)
    }

    delete data.arquivo
    const json = JSON.stringify(data)

    formData.append('data', json)

    return formData
  }
}

const GetAllByDespesa = (
  selector = {},
  options = {}
) => database(`despesa/${selector.despesaID}`, resourceName, LocalDB)[resourceName].find(selector, options)

const Create = (
  data = {},
  func = () => {}
) => database('', resourceName, LocalDB)[resourceName].upsert(data, func)

const Update = (
  data = {},
  func = () => {}
) => database('', resourceName, LocalDB)[resourceName].upsert(data, func)

const Delete = (
  id = '',
  success = () => {},
  error = () => {}
) => database(`${id}`, resourceName, LocalDB)[resourceName].remove(id, success, error)

const Sync = (success, error) =>
  upload(
    'upload',
    resourceName,
    LocalDB,
    data => toFormData(data)
  )[resourceName].upload(success, error)

const DespesaItemRepository = (localDB) => {
  LocalDB = localDB
  return {
    GetAllByDespesa,
    Create,
    Update,
    Delete,
    Sync
  }
}

export default DespesaItemRepository
