<template>
  <td :class="classCss" style="font-size: 11px;">
    <a v-if="IsText" v-on:click="$emit('Edit', obj)" href="javascript:void(0)"  v-bind:class="{'text-danger':(status === 0)}">{{ (value) ? value: 'N/D' }}</a>
    <a v-if="IsDatetime" v-on:click="$emit('Edit', obj)" href="javascript:void(0)"  v-bind:class="{'text-danger':(status === 0)}">{{ (value) ? moment(new Date(value)).format('DD/MM/YYYY HH:mm') : 'N/D' }}</a>
    <a v-if="IsDateOnly" v-on:click="$emit('Edit', obj)" href="javascript:void(0)"  v-bind:class="{'text-danger':(status === 0)}">{{ (value) ? moment(new Date(value)).format('DD/MM/YYYY') : 'N/D' }}</a>
    <div v-if="IsStatus" v-on:click="$emit('Edit', obj)">
      <span v-if="status" class="badge badge-success text-toupper">{{$lang.Common.Status.Active}}</span>
      <span v-else class="badge badge-danger text-toupper">{{$lang.Common.Status.Inactive}}</span>
    </div>
    <div v-if="IsActionsButtons">
      <i v-show="HasChange" v-if="status" v-on:click="$emit('Change', obj)" class="fa fa-close text-danger icon-grid" v-b-tooltip.hover :title="$lang.Common.Tooltip.ButtonInactive"></i>
      <i v-show="HasChange" v-else v-on:click="$emit('Change', obj)" class="fa fa-check text-success icon-grid" v-b-tooltip.hover :title="$lang.Common.Tooltip.ButtonActive"></i>
      &nbsp;
      <b-dropdown v-if="HasDelete" variant="link" size="sm" dropleft no-caret>
        <template slot="button-content">
          <i class="fa fa-trash-alt text-third icon-grid"></i><span class="sr-only">{{$lang.Common.Buttons.Delete}}</span>
        </template>
        <b-dropdown-header><strong>{{$lang.Common.ConfirmDelete}}</strong></b-dropdown-header>
        <b-dropdown-item href="javascript:void(0);" v-on:click="$emit('Delete', obj)"><i class="fas fa-check text-success"></i> {{$lang.Common.Yes}}</b-dropdown-item>
        <b-dropdown-item href="javascript:void(0);"><i class="fas fa-times text-danger"></i> {{$lang.Common.No}}</b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-if="IsActionsDetails">
      <i v-if="HasChange" v-on:click="$emit('Details', obj)" class="fa fa-plus-square-o" v-b-tooltip.hover :title="$lang.Common.Tooltip.ButtonDetails"></i>
    </div>
    <div v-if="IsLighthouse">
      <div v-if="value==0">
        <i v-if="HasChange" class="fa fa-circle icon-background-gray font-xl" v-b-tooltip.hover title="Não contém histórico/semáforo"></i>
      </div>
      <div v-if="value==1">
        <i v-if="HasChange" class="fa fa-circle icon-background-green font-xl"></i>
      </div>
      <div v-if="value==2">
        <i v-if="HasChange" class="fa fa-circle icon-background-yellow font-xl"></i>
      </div>
      <div v-if="value==3">
        <i v-if="HasChange" class="fa fa-circle icon-background-red font-xl"></i>
      </div>
      <div v-if="value==5">
        <i v-if="HasChange" class="fa fa-circle icon-background-blue font-xl" v-b-tooltip.hover :title="description"></i>
      </div>
    </div>
    <div v-if="IsSlot">
      <slot></slot>
    </div>
  </td>
</template>
<script>
export default {
  name: 'tableColumn',
  data () {
    return {
      obj: null
    }
  },
  props: {
    status: {
      type: Number,
      required: false,
      default: 0
    },
    value: {
      type: String,
      required: false,
      default: 'N/D'
    },
    classCss: {
      type: String,
      required: false,
      default: 'text-center'
    },
    IsText: {
      type: Boolean,
      required: false,
      default: false
    },
    IsDatetime: {
      type: Boolean,
      required: false,
      default: false
    },
    IsDateOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    IsStatus: {
      type: Boolean,
      required: false,
      default: false
    },
    IsActionsButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    IsActionsDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    IsLighthouse: {
      type: Boolean,
      required: false,
      default: false
    },
    IsSlot: {
      type: Boolean,
      required: false,
      default: false
    },
    HasChange: {
      type: Boolean,
      required: false,
      default: true
    },
    HasDelete: {
      type: Boolean,
      required: false,
      default: true
    },
    hours: {
      type: String,
      required: false,
      default: '00:00'
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
