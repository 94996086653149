import Vue from 'vue'
import VueRouter from 'vue-router'
import { JWTService } from '@/shared/services/jwt'

// Containers
import PublicBaseLayout from '@/containers/public/PublicLayout.vue'
import CommonLayout from '@/containers/private/CommonLayout.vue'
import ConstructionLayout from '@/containers/private/ConstructionLayout.vue'

// Public Views
import Authentication from '@/views/public/auth/Login.vue'

// Private Views
// import StartPage from '@/views/private/StartPage.vue'

// Miscellaneous Expenses (Despesas Diversas)
import MiscExpensesMenu from '@/views/private/expenses/miscellaneous/Menu.vue'
import MiscExpensesReleases from '@/views/private/expenses/miscellaneous/releases/Releases.vue'
import MiscExpensesAttach from '@/views/private/expenses/miscellaneous/releases/add/Attach.vue'
import MiscExpensesConfirmation from '@/views/private/expenses/miscellaneous/releases/Confirmation.vue'
import MiscExpensesType from '@/views/private/expenses/miscellaneous/releases/add/Type.vue'
import MiscExpenseDate from '@/views/private/expenses/miscellaneous/releases/add/Date.vue'
import MiscExpensesQuantity from '@/views/private/expenses/miscellaneous/releases/add/Quantity.vue'
import MiscExpensesValue from '@/views/private/expenses/miscellaneous/releases/add/Value.vue'
import MiscExpensesComments from '@/views/private/expenses/miscellaneous/releases/add/Comments.vue'
import MiscExpensesClosings from '@/views/private/expenses/miscellaneous/closings/Closings.vue'
import MiscExpensesClosingDetails from '@/views/private/expenses/miscellaneous/closings/Details.vue'
import MiscAuthorizationConstruction from '@/views/private/expenses/miscellaneous/authorizations/Construction.vue'
import MiscAuthorizationResponsable from '@/views/private/expenses/miscellaneous/authorizations/Responsable.vue'
import MiscAuthorizationRegional from '@/views/private/expenses/miscellaneous/authorizations/Regional.vue'
import MiscAuthorizationFavored from '@/views/private/expenses/miscellaneous/authorizations/Favored.vue'
import MiscAuthorizationHasTeam from '@/views/private/expenses/miscellaneous/authorizations/HasTeam.vue'
import MiscAuthorizationValue from '@/views/private/expenses/miscellaneous/authorizations/Value.vue'
import MiscAuthorizationDescription from '@/views/private/expenses/miscellaneous/authorizations/Description.vue'
import MiscAuthorizationTerm from '@/views/private/expenses/miscellaneous/authorizations/Term.vue'

// Travel Expenses
import TravelExpensesMenu from '@/views/private/expenses/travel/Menu.vue'
import TravelExpensesReleases from '@/views/private/expenses/travel/releases/Releases.vue'
import TravelExpensesAttach from '@/views/private/expenses/travel/releases/add/Attach.vue'
import TravelExpensesType from '@/views/private/expenses/travel/releases/add/Type.vue'
import TravelExpenseDate from '@/views/private/expenses/travel/releases/add/Date.vue'
import TravelExpensesQuantity from '@/views/private/expenses/travel/releases/add/Quantity.vue'
import TravelExpensesValue from '@/views/private/expenses/travel/releases/add/Value.vue'
import TravelExpensesClosings from '@/views/private/expenses/travel/closings/Closings.vue'
import TravelRequestConstruction from '@/views/private/expenses/travel/request/Construction.vue'
import TravelRequestRegional from '@/views/private/expenses/travel/request/Regional.vue'
import TravelRequestResponsable from '@/views/private/expenses/travel/request/Responsable.vue'
import TravelRequestCityOrigin from '@/views/private/expenses/travel/request/CityOrigin.vue'
import TravelRequestCityDestiny from '@/views/private/expenses/travel/request/CityDestiny.vue'
import TravelRequestCar from '@/views/private/expenses/travel/request/Car.vue'
import TravelRequestCarModel from '@/views/private/expenses/travel/request/CarModel.vue'
import TravelRequestLicensePlate from '@/views/private/expenses/travel/request/LicensePlate.vue'
import TravelRequestFuel from '@/views/private/expenses/travel/request/Fuel.vue'
import TravelRequestDescription from '@/views/private/expenses/travel/request/Description.vue'

// Reimbursement (Reembolso)
import ReimbursementMenu from '@/views/private/expenses/reimbursement/Menu.vue'
import ReimbursementReleases from '@/views/private/expenses/reimbursement/releases/Releases.vue'
import ReimbursementAttach from '@/views/private/expenses/reimbursement/releases/add/Attach.vue'
import ReimbursementType from '@/views/private/expenses/reimbursement/releases/add/Type.vue'
import ReimbursementExpenseDate from '@/views/private/expenses/reimbursement/releases/add/Date.vue'
import ReimbursementQuantity from '@/views/private/expenses/reimbursement/releases/add/Quantity.vue'
import ReimbursementValue from '@/views/private/expenses/reimbursement/releases/add/Value.vue'
import ReimbursementClosings from '@/views/private/expenses/reimbursement/closings/Closings.vue'
import ReimbursementAuthorizationConstruction from '@/views/private/expenses/reimbursement/authorizations/Construction.vue'
import ReimbursementAuthorizationResponsable from '@/views/private/expenses/reimbursement/authorizations/Responsable.vue'
import ReimbursementAuthorizationRegional from '@/views/private/expenses/reimbursement/authorizations/Regional.vue'
import ReimbursementAuthorizationFavored from '@/views/private/expenses/reimbursement/authorizations/Favored.vue'
import ReimbursementAuthorizationHasTeam from '@/views/private/expenses/reimbursement/authorizations/HasTeam.vue'
import ReimbursementAuthorizationValue from '@/views/private/expenses/reimbursement/authorizations/Value.vue'
import ReimbursementAuthorizationDescription from '@/views/private/expenses/reimbursement/authorizations/Description.vue'
import ReimbursementAuthorizationTerm from '@/views/private/expenses/reimbursement/authorizations/Term.vue'
import Historico from '@/views/private/Historico/Historico.vue'
import Login from '@/views/private/Login/Login.vue'
import Consultas from '../views/private/Consultas/Consultas.vue'
import Profile from '../views/private/Profile/Profile.vue'
Vue.use(VueRouter)

/**
 * @function assertLoggedIn
 * @description Middleware para ser executado antes da troca de rota para garantir
 * que os usuários estão autenticados para ver esta rota.
 * @param {Object} to A rota de onde o usuário está vindo.
 * @param {Object} from A rota que o usuário deseja acessar.
 * @param {Object} next A rota que será renderizada.
 */
// const assertLoggedIn = (to, from, next) => {
//   if (!JWTService.GetToken()) {
//     next({ name: 'SignIn' })
//     return
//   }
//   next()
// }
/**
 * @function assertNotLoggedIn
 * @description Middleware para ser executado antes da troca de rota para garantir
 * que os usuários não estão autenticados para ver esta rota.
 * @param {Object} to A rota de onde o usuário está vindo.
 * @param {Object} from A rota que o usuário deseja acessar.
 * @param {Object} next A rota que será renderizada.
 */
const assertNotLoggedIn = (to, from, next) => {
  if (JWTService.GetToken()) {
    next({ name: 'Consultas' })
    return
  }
  next()
}

/**
 * @function route
 * @description Cria um objeto de rota.
 * @param {string} path O caminho da rota.
 * @param {string} name O nome da rota.
 * @param {Object} component O componente a ser renderizado na rota.
 * @param {Array} children As children routes desta rota.
 * @returns {Object} Um objeto de rota do vue-router.
 */
const route = (path, name, component, children, beforeEnter, meta) => ({
  path,
  name,
  component,
  children,
  beforeEnter,
  meta
})

/**
 * @function children
 * @description Transforma um objetos com rotas em array.
 * @param {...} routes As rotas para serem retornadas em forma de array.
 * @returns {Array} O array com as rotas.
 */
const children = (...routes) => routes

/**
 * @property adminRoutes
 * @description As rotas privadas da aplicação.
 * @type {Object}
 */
const commonRoutes = {
  path: '/',
  redirect: '/consultas',
  component: CommonLayout,
  // beforeEnter: assertLoggedIn,
  children: children(
    route('/consultas', 'Consultas', Consultas),
    // MISC EXPENSES
    route('expenses/misc/menu', 'MiscExpensesMenu', MiscExpensesMenu),
    route('expenses/misc/closings', 'MiscExpensesClosings', MiscExpensesClosings),
    route('expenses/misc/closings/details', 'MiscExpensesClosingDetails', MiscExpensesClosingDetails),
    route('expenses/mics/authorization/construction', 'MiscAuthorizationConstruction', MiscAuthorizationConstruction),
    route('expenses/mics/authorization/regional', 'MiscAuthorizationRegional', MiscAuthorizationRegional),
    route('expenses/mics/authorization/responsable', 'MiscAuthorizationResponsable', MiscAuthorizationResponsable),
    route('expenses/mics/authorization/favored', 'MiscAuthorizationFavored', MiscAuthorizationFavored),
    route('expenses/mics/authorization/hasteam', 'MiscAuthorizationHasTeam', MiscAuthorizationHasTeam),
    route('expenses/mics/authorization/value', 'MiscAuthorizationValue', MiscAuthorizationValue),
    route('expenses/mics/authorization/description', 'MiscAuthorizationDescription', MiscAuthorizationDescription),
    route('expenses/mics/authorization/term', 'MiscAuthorizationTerm', MiscAuthorizationTerm),
    // TRAVEL EXPENSES
    route('expenses/travel/menu', 'TravelExpensesMenu', TravelExpensesMenu),
    route('expenses/travel/closings', 'TravelExpensesClosings', TravelExpensesClosings),
    // TRAVEL REQUEST
    route('expenses/travel/request/construction', 'TravelRequestConstruction', TravelRequestConstruction),
    route('expenses/travel/request/regional', 'TravelRequestRegional', TravelRequestRegional),
    route('expenses/travel/request/responsable', 'TravelRequestResponsable', TravelRequestResponsable),
    route('expenses/travel/request/city/origin', 'TravelRequestCityOrigin', TravelRequestCityOrigin),
    route('expenses/travel/request/city/destiny', 'TravelRequestCityDestiny', TravelRequestCityDestiny),
    route('expenses/travel/request/car', 'TravelRequestCar', TravelRequestCar),
    route('expenses/travel/request/car/model', 'TravelRequestCarModel', TravelRequestCarModel),
    route('expenses/travel/request/car/plate', 'TravelRequestLicensePlate', TravelRequestLicensePlate),
    route('expenses/travel/request/car/fuel', 'TravelRequestFuel', TravelRequestFuel),
    route('expenses/travel/request/description', 'TravelRequestDescription', TravelRequestDescription),
    // REIMBURSEMENT
    route('expenses/reimbursement/menu', 'ReimbursementMenu', ReimbursementMenu),
    route('expenses/reimbursement/closings', 'ReimbursementClosings', ReimbursementClosings),
    route('expenses/reimbursement/authorization/construction', 'ReimbursementAuthorizationConstruction', ReimbursementAuthorizationConstruction),
    route('expenses/reimbursement/authorization/regional', 'ReimbursementAuthorizationRegional', ReimbursementAuthorizationRegional),
    route('expenses/reimbursement/authorization/responsable', 'ReimbursementAuthorizationResponsable', ReimbursementAuthorizationResponsable),
    route('expenses/reimbursement/authorization/favored', 'ReimbursementAuthorizationFavored', ReimbursementAuthorizationFavored),
    route('expenses/reimbursement/authorization/hasteam', 'ReimbursementAuthorizationHasTeam', ReimbursementAuthorizationHasTeam),
    route('expenses/reimbursement/authorization/value', 'ReimbursementAuthorizationValue', ReimbursementAuthorizationValue),
    route('expenses/reimbursement/authorization/description', 'ReimbursementAuthorizationDescription', ReimbursementAuthorizationDescription),
    route('expenses/reimbursement/authorization/term', 'ReimbursementAuthorizationTerm', ReimbursementAuthorizationTerm),
    route('/TesteLogin', 'TesteLogin', Login),
    route('/Historico', 'Historico', Historico),
    route('/consultas', 'Consultas', Consultas),
    route('/profile', 'Profile', Profile)
  )
}

const constructionRoutes = {
  path: '/',
  redirect: '/start-page',
  component: ConstructionLayout,
  // beforeEnter: assertLoggedIn,
  children: children(
    // MISC EXPENSES
    route('expenses/misc/releases', 'MiscExpensesReleases', MiscExpensesReleases, null, null, { area: 1 }),
    route('expenses/misc/type', 'MiscExpensesType', MiscExpensesType, null, null, { area: 1, obraRhEnabled: false }),
    route('expenses/misc/date', 'MiscExpenseDate', MiscExpenseDate, null, null, { area: 1, obraRhEnabled: false }),
    route('expenses/misc/quantity', 'MiscExpensesQuantity', MiscExpensesQuantity, null, null, { area: 1, obraRhEnabled: false }),
    route('expenses/misc/value', 'MiscExpensesValue', MiscExpensesValue, null, null, { area: 1, obraRhEnabled: false }),
    route('expenses/misc/comments', 'MiscExpensesComments', MiscExpensesComments, null, null, { area: 1, obraRhEnabled: false }),
    route('expenses/misc/attach', 'MiscExpensesAttach', MiscExpensesAttach, null, null, { area: 1, obraRhEnabled: false }),
    route('expenses/misc/confirmation', 'MiscExpensesConfirmation', MiscExpensesConfirmation, null, null, { area: 1, obraRhEnabled: false }),
    // TRAVEL EXPENSES
    route('expenses/travel/releases', 'TravelExpensesReleases', TravelExpensesReleases, null, null, { area: 2 }),
    route('expenses/travel/type', 'TravelExpensesType', TravelExpensesType, null, null, { area: 2, obraRhEnabled: false }),
    route('expenses/travel/date/:type', 'TravelExpenseDate', TravelExpenseDate, null, null, { area: 2, obraRhEnabled: false }),
    route('expenses/travel/quantity/:type/:date', 'TravelExpensesQuantity', TravelExpensesQuantity, null, null, { area: 2, obraRhEnabled: false }),
    route('expenses/travel/value/:type/:date/:quantity', 'TravelExpensesValue', TravelExpensesValue, null, null, { area: 2, obraRhEnabled: false }),
    route('expenses/travel/attach/:type/:date/:quantity/:value', 'TravelExpensesAttach', TravelExpensesAttach, null, null, { area: 2, obraRhEnabled: false }),
    // REIMBURSEMENT
    route('expenses/reimbursement/releases', 'ReimbursementReleases', ReimbursementReleases, null, null, { area: 3 }),
    route('expenses/reimbursement/type', 'ReimbursementType', ReimbursementType, null, null, { area: 3, obraRhEnabled: false }),
    route('expenses/reimbursement/date/:type', 'ReimbursementExpenseDate', ReimbursementExpenseDate, null, null, { area: 3, obraRhEnabled: false }),
    route('expenses/reimbursement/quantity/:type/:date', 'ReimbursementQuantity', ReimbursementQuantity, null, null, { area: 3, obraRhEnabled: false }),
    route('expenses/reimbursement/value/:type/:date/:quantity', 'ReimbursementValue', ReimbursementValue, null, null, { area: 3, obraRhEnabled: false }),
    route('expenses/reimbursement/attach/:type/:date/:quantity/:value', 'ReimbursementAttach', ReimbursementAttach, null, null, { area: 3, obraRhEnabled: false })
  )
}

/**
 * @property publicRoutes
 * @description As rotas públicas da aplicação. (Não requerem autenticação)
 * @type {Object}
 */

const publicRoutes = {
  path: '/',
  redirect: '/login',
  component: PublicBaseLayout,
  children: children(
    route('/login', 'SignIn', Authentication, null, assertNotLoggedIn)
  )
}

const router = new VueRouter({
  mode: 'history',
  inkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    publicRoutes,
    commonRoutes,
    constructionRoutes,
    {
      path: '*',
      component: PublicBaseLayout
    },
    {
      method: 'GET',
      path: '/static/fonts/:file',
      type: 'passthrough',
      service: 'static/fonts/:file'
    }
  ]
})

export default router
