export const state = {
  accountLogged: null,
  company: {
    companyID: null,
    name: null,
    zCode: null
  },
  typeExpenses: null
}

export default state
