import { database } from '../config'

let LocalDB
const resourceName = 'despesatipo'

const GetAllByDiversos = (
  selector = {},
  options = {}
) => database('diversos', resourceName, LocalDB)[resourceName].find(selector, options)

const GetByID = (
  selector = {},
  success = () => {}
) => database(`/${selector.id}`, resourceName, LocalDB)[resourceName].findOne(selector, success)

const DespesaTipoRepository = (localDB) => {
  LocalDB = localDB
  return {
    GetAllByDiversos,
    GetByID
  }
}

export default DespesaTipoRepository
