<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.MiscExpensesComments.LabelTitle }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.MiscExpensesComments.LabelComment }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-textarea
            id="comments"
            name="comments"
            :placeholder="$lang.MiscExpensesComments.PlaceholderComment"
            v-model="comments"
            required
            autofocus
            rows="10"
            no-resize
          />
        </b-card-text>
        <div class="text-center align-self-end">
          <button
            type="button"
            class="btn btn-third text-bold"
            @click="onAdd"
          >
            <i class="fas fa-check-circle"/>&nbsp;{{ $lang.MiscExpensesComments.ButtonAdd }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'MiscExpensesComments',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      comments: ''
    }
  },
  computed: {
    isEditing: function () {
      return this.$route.params && this.$route.params.edit
    },
    editingRelease: function () {
      return this.$route.params.despesaItem
    }
  },
  methods: {
    onLoad () {
      if (this.isEditing) {
        this.comments = this.editingRelease.observacao
      }
    },
    onAdd () {
      this.$router.push({ name: 'MiscExpensesAttach', params: { ...this.$route.params, comments: this.comments } })
    }
  }
}
</script>
