import APIClient from '../../../../shared/http/clients/api'

const resourceName = 'Funcionario'
const APIBusinessCommon = process.env.VUE_APP_BusinessCommon

const GetPerfil = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetPerfil/`, data, config)
const GetCordinators = (data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetCordinators/`, data, config)
const GetCordinatorsByName = (nome, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetCoordenadorByName/${nome}`, data, config)
const GetTecnicoByName = (nome, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetTecnicoByName/${nome}`, data, config)

const GetResetSenhaZpagFuncionario = (username, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetResetSenhaZpagFuncionario?username=${username}`, config)

const profileExpensesRepository = {

  GetPerfil,
  GetCordinators,
  GetCordinatorsByName,
  GetTecnicoByName,
  GetResetSenhaZpagFuncionario

}

export default profileExpensesRepository
