<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-4 text-center">
          <i class="fa fa-plus-circle footer-icon" aria-hidden="true" style="color: #fff;" :to="{ name: 'Details'}"></i>
        </div>
        <div class="col-4 text-center" style="border-left: solid 1px #ccc; border-right: solid 1px #ccc">
          <i class="fa fa-search footer-icon" aria-hidden="true"></i>
        </div>
        <div class="col-4 text-center">
          <i class="fa fa-user footer-icon" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppFooter',
  methods: {
    onGotoHome () {
      this.$router.push({ name: 'Dashboard' })
    }
  }
}
</script>
