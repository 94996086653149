<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.TravelRequest.LabelTitle }} | {{ $lang.TravelRequest.CityOrigin.LabelDescription }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.TravelRequest.CityOrigin.LabelCountry }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-select
            id="country"
            name="country"
            v-model="country.value"
            :options="country.options"
            data-vv-as="country"
            v-validate="'required'"
            aria-describedby="countryErrorFeedback"
          >
            <template #first>
              <option :value="null" disabled>
                {{ $lang.Common.Select }}
              </option>
            </template>
          </b-form-select>
        </b-card-text>

        <div class="text-third text-bold">{{ $lang.TravelRequest.CityOrigin.LabelState }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-select
            id="state"
            name="state"
            :disabled="country.value === null"
            v-model="state.value"
            :options="state.options"
            data-vv-as="state"
            v-validate="'required'"
            aria-describedby="stateErrorFeedback"
          >
            <template #first>
              <option :value="null" disabled>
                {{ $lang.Common.Select }}
              </option>
            </template>
          </b-form-select>
        </b-card-text>

        <div class="text-third text-bold">{{ $lang.TravelRequest.CityOrigin.LabelCity }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-select
            id="city"
            name="city"
            :disabled="state.value === null"
            v-model="city.value"
            :options="city.options"
            data-vv-as="city"
            v-validate="'required'"
            aria-describedby="cityErrorFeedback"
          >
            <template #first>
              <option :value="null" disabled>
                {{ $lang.Common.Select }}
              </option>
            </template>
          </b-form-select>
        </b-card-text>
        <div class="text-center align-self-end">
          <button
            type="button"
            class="btn btn-third text-bold"
            @click="onAdd"
          >
            <i class="fas fa-plus-circle"/>&nbsp;{{ $lang.TravelRequest.CityOrigin.ButtonAdd }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'TravelRequestCityOrigin',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      country: {
        value: null,
        options: []
      },
      state: {
        value: null,
        options: []
      },
      city: {
        value: null,
        options: []
      }
    }
  },
  watch: {
    'country.value': function (value) {
      if (value) {
        this.onLoadAllStates(value)
      } else {
        this.state.value = null
        this.state.options = []
      }
    },
    'state.value': function (value) {
      if (value) {
        this.onLoadAllCities(value)
      } else {
        this.city.value = null
        this.city.options = []
      }
    }
  },
  mounted () {
    this.onLoadAllCountries()
  },
  methods: {
    onAdd () {
      if (this.city.value) {
        this.$router.push({ name: 'TravelRequestCityDestiny', params: { ...this.$route.params, origemID: this.city.value } })
      } else {
        this.toast.error(this.$lang.TravelRequest.CityOrigin.Errors.CityOriginRequired)
      }
    },
    onLoadAllCountries () {
      this.$database.core.paisRepository.GetAll({}, {})
        .fetch(data => {
          this.country.options = data.map(r => {
            return {
              text: r.nome,
              value: r.id
            }
          })
          this.loading.pop()
        }, error => {
          console.log(error)
          this.loading.pop()
        })
    },
    onLoadAllStates (paisID) {
      this.$database.core.estadoRepository.GetAll({ paisID }, {})
        .fetch(data => {
          this.state.options = data.map(r => {
            return {
              text: r.nome,
              value: r.id
            }
          })
          this.loading.pop()
        }, error => {
          console.log(error)
          this.loading.pop()
        })
    },
    onLoadAllCities (estadoID) {
      this.$database.core.cidadeRepository.GetAll({ estadoID }, {})
        .fetch(data => {
          this.city.options = data.map(r => {
            return {
              text: r.nome,
              value: r.id
            }
          })
          this.loading.pop()
        }, error => {
          console.log(error)
          this.loading.pop()
        })
    }
  }
}
</script>
