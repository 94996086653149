<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.ReimbursementValue.LabelTitle }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.ReimbursementValue.LabelEnterExpenseValue }}</div>
        <b-card-text class="w-100 my-3">
          <money
            :placeholder="'0,00'"
            class="form-control"
            style="text-align: right;"
            v-model.lazy="value"
            data-vv-as="value"
            v-validate="'required'"
            aria-describedby="valueErrorFeedback"
            :state="errors.has('value') ? false : getValidationState('value')"
            v-bind="$lang.Currency.Format"
            @blur.native="getValidationState('value')"
          ></money>
        </b-card-text>
        <div class="text-center align-self-end">
          <button type="button" class="btn btn-third text-bold" @click="onAdd"><i class="fas fa-plus-circle"/>&nbsp;{{ $lang.ReimbursementValue.ButtonAdd }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'ExpenseType',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      value: 0
    }
  },
  methods: {
    onAdd () {
      this.$validator.validateAll()
        .then(valid => {
          if (valid && this.value > 0) {
            this.$router.push({ name: 'ReimbursementAttach', params: { ...this.$route.params, value: this.value } })
          } else {
            this.toast.error(this.$lang.ReimbursementValue.Errors.ExpenseQuantityRequired)
          }
        })
    }
  }
}
</script>
