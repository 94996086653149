<template>
  <div class="card">
    <div>
    <div class="container-login" v-if="!fgtSenha">
      <div class="login">
        <div class="login-more"></div>
        <form class="login-form validate-form" autocomplete="off" style="padding:35px;"></form>
          <div class="imagem">
            <img src="/static/img/logo/menu/LogoZoponeMenu.svg" style="width: 200px; height: 3rem;">
          </div>
          <div class="text-up" style="color: #008643; font-size: 20px; font-weight: bold; text-align: center;">
            <small>{{ $t('login.welcome') }}</small>
          </div>
          <div class="text-center" style="font: normal normal 600 15px/24px Segoe UI; color: #606160;">
            <small>{{ $t('login.description') }}</small>
          </div>
        <div class="input-group">
          <span class="input-group-addon"><i class="fas fa-user"></i></span>
          <b-form-input
            class="form-control"
            type="text"
            id="username"
            name="username"
            :placeholder="errors.has('username') ? errors.first('username') : this.$t('login.username')"
            v-model="credentials.username"
            data-vv-as="username"
            v-validate="'required'"
            aria-describedby="usernameErrorFeedback"
            :state="errors.has('username') ? false : getValidationState('username')"
            @blur="getUserCompanies"
            @blur.native="getValidationState('username')"
            >
          </b-form-input>
        </div>
      </div>

        <div class="input-group">
            <span class="input-group-addon"><i class="fas fa-lock"></i></span>
            <b-form-input type="password"
              id="password"
              name="password"
              class="form-control"
              :placeholder="errors.has('password') ? errors.first('password') : this.$t('login.password')"
              v-model="credentials.password"
              data-vv-as="senha"
              v-validate="'required'"
              aria-describedby="passwordErrorFeedback"
              :state="errors.has('password') ? false : getValidationState('password')"
              @blur.native="getValidationState('password')">
            </b-form-input>
        </div>

          <div class="input-group">
            <span class="input-group-addon"><i class="fas fa-building"></i></span>
            <b-form-select
                id="company"
                name="company"
                v-model="credentials.company"
                class="form-control"
                data-vv-as="empresa"
                v-validate="'required'"
                aria-describedby="companyErrorFeedback"
                :state="errors.has('company') ? false : getValidationState('company')"
                :options="companies"
                @change="checkCompanyLocale">

                <template #first>
                  <option :value="undefined" disabled>
                    {{ $t('login.company') }}
                  </option>
                </template>
            </b-form-select>
          </div>

          <b-card-text class="container-botao-login text-center">
            <b-button class="botao-login" @click="validateAndSignIn"><i class="fas fa-sign-in-alt"></i>
              {{ $t('button.login') }}
            </b-button>
          </b-card-text>

          <div class="flex-sb-m w-full p-t-3 p-b-32" style="display:flex; justify-content:center">
            <a href="#" class="txt1" @click="menuSenha" style="color: #008643; text-decoration: underline;">
              Esqueci a senha
            </a>
          </div>
          <div class="d-flex container-botao-login mt-3">
            {{  $t('version') }} : {{ version }}
          </div>
      </div>
      <div class="container-login" v-else>
        <div class="login">
          <form class="login-form validate-form" autocomplete="off" style="padding:35px;"></form>
          <div class="imagem">
            <img src="/static/img/logo/menu/LogoZoponeMenu.svg" style="width: 200px; height: 3rem;">
          </div>
          <div class="text-up" style="color: #008643; font-size: 20px; font-weight: bold; text-align: center;">
            <small>Recuperação de Senha</small>
          </div>
          <div class="text-center" style="font: normal normal 600 20px/24px Segoe UI; color: #606160;">
            <small>Informe o usuário</small>
          </div>
          <div class="input-group">
          <span class="input-group-addon"><i class="fas fa-user"></i></span>
          <b-form-input
            class="form-control"
            type="text"
            id="username"
            name="username"
            :placeholder="errors.has('username') ? errors.first('username') : 'Username'"
            v-model="credentials.username"
            data-vv-as="username"
            >
          </b-form-input>
        </div>
          <!-- <div class="input-group">
          <span class="input-group-addon"><i class="fas fa-user"></i></span>
          <b-form-input
            class="form-control"
            type="text"
            id="email"
            name="email"
            :placeholder="errors.has('@') ? errors.first('.com') : 'Email'"
            v-model="credentials.email"
            data-vv-as="email"
            v-validate="'required'"
            aria-describedby="emailErrorFeedback"
            :state="errors.has('@') ? false : getValidationState('email')"
            @blur.native="getValidationState('email')"
            >
          </b-form-input>
        </div> -->

        <b-card-text class="container-botao-login text-center">
            <b-button class="botao-login" @click="validateForgetPassword"><i class="fas fa-sign-in-alt"></i>
              Enviar
            </b-button>
          </b-card-text>
          <div class="flex-sb-m w-full p-t-3 p-b-32" style="display:flex; justify-content:center">
            <a href="#" class="txt1" @click="menuSenha" style="color: #008643; text-decoration: underline;">
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from './Login.js'
export default Login
</script>

<style scoped>

.card{
  border-radius: 15px;
  padding: 1em 4em 2em 4em;
}

.container-login {
    display:inline;
    justify-content: center;
}

.input-group {
  margin-bottom: 5%;
  margin-top: 5%;
}

.imagem {
  display: flex;
  justify-content: center;
}

.container-botao-login {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.botao-login {
  background-color: #008643;
  height: 35px;
  font-size: 15px;
  border-radius: 25px;
  color: white;
  margin: 2%;
  width: 70%;
  border: none;
}
</style>
