<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.ReimbursementAuthorization.LabelTitle }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.ReimbursementAuthorization.Construction.LabelDescription }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-input
            id="code"
            name="code"
            placeholder="Código"
            v-model="obra.code"
            data-vv-as="code"
            v-validate="'required'"
            aria-describedby="codeErrorFeedback"
            @blur="onChangeObraRH"
            />
          <b-form-input
            id="name"
            name="name"
            placeholder="Obra"
            class="mt-3"
            v-model="obra.name"
            readonly
          />
        </b-card-text>
        <div class="text-center align-self-end">
          <button
            type="button"
            class="btn btn-third text-bold"
            @click="onAdd"
          >
            <i class="fas fa-plus-circle"/>&nbsp;{{ $lang.ReimbursementAuthorization.Construction.ButtonAdd }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'ReimbursementAuthorizationConstruction',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      obras: [],
      obra: {
        id: null,
        code: null,
        name: null
      }
    }
  },
  methods: {
    onAdd () {
      if (this.obra.id && this.obra.code && this.obra.name) {
        this.$router.push({ name: 'ReimbursementAuthorizationRegional', params: { obraRHID: this.obra.id } })
      } else {
        this.toast.error(this.$lang.ReimbursementAuthorization.Construction.Errors.ConstructionCodeRequired)
      }
    },
    onChangeObraRH () {
      this.loading.push()

      this.$database.businessCommon.obraRHRepository.GetByCode({ empresaID: this.company.companyID, codigo: parseInt(this.obra.code) }, {},
        obj => {
          if (obj) {
            this.obra.id = obj.id
            this.obra.name = obj.nome
          } else {
            this.obra.id = null
            this.obra.name = null
          }
          this.loading.pop()
        }, error => {
          this.obra.id = null
          this.obra.name = null
          console.log(error)
          this.loading.pop()
        })
    }
  }
}
</script>
