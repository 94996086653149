import APIClient from '../clients/httpClient.js'
import APIClientUpload from '../clients/httpClientUpload.js'
import Minimongo from 'minimongo'

export const databaseName = 'coredb'
export const baseURL = `${process.env.VUE_APP_Core}/`

/**
 * Export a Local Database (IndexedDB) Instance
 */
export const localDB = new Minimongo.IndexedDb({ namespace: databaseName }, () => {
  console.log('CoreDB instace created')
}, error => {
  console.error('Could not create local CoreDB instance')
  console.error(error)
})

/**
 * Export Hybrid Database Function
 * @param {*} resourceName
 * @param {*} config
 * @returns
 */
export const database = (endPoint, resourceName, localDB, proccessData = data => data) => {
  const remoteDB = new Minimongo.RemoteDb(baseURL, databaseName, (method = '', url, params = {}, data = {}, success = () => {}, error = () => {}) => APIClient(endPoint, method, url, params, proccessData(data), success, error))
  const hybridDB = new Minimongo.HybridDb(localDB, remoteDB)

  remoteDB.addCollection(resourceName)
  localDB.addCollection(resourceName)
  hybridDB.addCollection(resourceName)

  return hybridDB
}

export const upload = (endPoint, resourceName, localDB, proccessData = data => data) => {
  const remoteDB = new Minimongo.RemoteDb(baseURL, databaseName, (method = '', url, params = {}, data = {}, success = () => {}, error = () => {}) => APIClientUpload(endPoint, method, url, params, proccessData(data), success, error))
  const hybridDB = new Minimongo.HybridDb(localDB, remoteDB)

  remoteDB.addCollection(resourceName)
  localDB.addCollection(resourceName)
  hybridDB.addCollection(resourceName)

  return hybridDB
}
