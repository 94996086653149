<template>
    <div class="consultas-container">
        <div class="saldo-container">
            <div class="saldo-title"></div>
            <div class="saldo-card">
                <div class="saldo-card-title">SALDO: {{totalExpensesLimitFormatted}}</div>
                <ProgressBar v-if="!verificaDetails" class="progress-bar"  :value="balanceUsed" :showValue="false" />
                <ProgressBar v-if="verificaDetails" class="progress-bar" :value="balanceUsed" :showValue="false" />
                <div class="saldo-card-info" >
                    <div v-if="verificaDetails === false" class="used-available-container SaldoUtilizado">
                        <div v-if="!verificaDetails" class="formatedProgressBalanceAvailable"> {{ $t('consultation.balance_used') }} </div>
                        <div v-else class="formatedProgressText"> {{ $t('consultation.balance_used') }} </div>
                        <div class="formatedProgressText">{{expensesTotalFormatted}}</div>
                    </div>
                    <div v-if="verificaDetails === false" class="used-available-container">
                       <div class="formatedProgressText"> {{ $t('consultation.balance_available') }} </div>
                       <div class="formatedProgressText">{{balanceValueFormatted}}</div>
                    </div>
                </div>
                <div class="w-100">
                    <b-collapse id="my-collapse" accordition="accorditionDetails" role="tabPainel">
                        <b-card-body class="w-100 p-0">
                            <hr>
                            <div class="w-100">
                                <div class="d-flex justify-content-center">
                                    <span class="mb-3">
                                        {{ $t('consultation.balance_details') }}
                                    </span>
                                </div>
                                <div>
                                    <div class="d-flex w-100 saldo-card-info-details flex-direction-column">
                                        <!-- <div class="used-available-container"> -->
                                            <div class="d-flex" style="justify-content: space-between;">
                                            <div class="mb-2">
                                                <i class="fa fa-circle-info mr-2"></i><span class="valueToSend"> {{ $t('consultation.expenses_forwarded') }}</span>
                                            </div>
                                            <span class="valueToSend">{{ balanceToSendFormatted }}</span>
                                        </div>
                                        <div class="d-flex" style="justify-content: space-between;">
                                            <div class="mb-2">
                                                <i class="fa fa-clock mr-2"></i><span class="formatedProgressTextDetails"> {{$t('consultation.expenses_waitApproval')}}</span>
                                            </div>
                                            <span style="color:#de852c; font-size:12px; font-weight: bold;">{{ balanceWaitingAprovedFormatted }}</span>
                                        </div>
                                        <div v-if="verificaDetails === true" class="d-flex" style="justify-content: space-between;">
                                            <div class="formatedProgressText">
                                                <svg style="scale:1.5" class="mr-2" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M320 96H192L144.6 24.9C137.5 14.2 145.1 0 157.9 0H354.1c12.8 0 20.4 14.2 13.3 24.9L320 96zM192 128H320c3.8 2.5 8.1 5.3 13 8.4C389.7 172.7 512 250.9 512 416c0 53-43 96-96 96H96c-53 0-96-43-96-96C0 250.9 122.3 172.7 179 136.4l0 0 0 0c4.8-3.1 9.2-5.9 13-8.4zm84 88c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V424c0 11 9 20 20 20s20-9 20-20V410.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l0 0-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V216z"/></svg>
                                                {{ $t('consultation.balance_available') }} </div>
                                            <div class="formatedProgressText">{{balanceValueFormatted}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                    <b-card-header class="d-flex justify-content-center" header-tag="header" role="tab" >
                        <div v-b-toggle:my-collapse>
                            <span class="when-closed" @click="verificaDetails = !verificaDetails">
                                <i class="fas fa-chevron-down" ></i>
                            </span>
                            <span class="when-open" @click="verificaDetails = !verificaDetails">
                                <i class="fas fa-chevron-up"></i>
                            </span>
                        </div>
                    </b-card-header>
                </div>
                </div>
        </div>
        <div class="lancamentos-container">
            <div class="titulo-container" style="margin-bottom: 10px">{{$t('consultation.releases')}}</div>
            <div class="lancamentos-header">
                <div class="lancamentos-search-container">
                    <i class="fa fa-search icon-background"></i>
                    <select v-if="typeExpenses === 0" v-model="formAddDe.filtro" class="form-filtro" placeholder="Selecione" @change="onChangeFiltro($event)">
                                <option hidden>{{ $t('options.select') }}</option>
                                <option v-for="(data,index) in $t('consultation.dataSearchAll')" :key="index" :value="data">{{data.text}}</option>
                    </select>
                    <select v-else v-model="formAddDe.filtro" class="form-filtro" placeholder="Selecione" @change="onChangeFiltro($event)">
                                <option hidden>{{ $t('options.select') }}</option>
                                <option v-for="(data,index) in $t('options.subcategoriesmiscellaneous')" :key="index" :value="data">{{data.text}}</option>
                    </select>
                    <div @click="AtualizaPagina()" v-if="formAddDe.filtro !== null">
                    <i class="fa fa-times"></i>
                    </div>
                    <!-- <input class="search-input" type="text" placeholder="Pesquisar"/> -->
                </div>
                    <button v-if="verificarSaldoFuncionario" class="btn btn-add" @click="openIncluirDespesa()">
                    </button>
                    <button class="btn btn-send" v-b-modal.modal-4 v-if="userCanSendExpenses">
                        <!-- <i class="fa fa-paper-plane" aria-hidden="true"></i> -->
                    </button>
                    <b-modal hide-footer  id="modal-1" :title="!this.edit ? $t('add_expenses.title-add') : $t('add_expenses.title-edit')" ref="modalone">
                        <div class="moda-compl">

                        <div class="scrollbar-AddDespesa">
                            <p class="my-4">{{ $t('add_expenses.subTitle') }}</p>
                            <div v-if="formAddDe.dataMotivoRecusa">
                                <div class="conteiner-motivo">
                                    <div class="data-picker-container">
                                    <label class="infoLabel-motivo" for="range-1"> Data Despesa Devolvida</label>
                                    <b-form-datepicker
                                    v-if="$i18n.locale == 'escl'"
                                        disabled
                                          class   = "form-control"
                                          locale  = "es-Cl"
                                          v-model = "formAddDe.dataMotivoRecusa"
                                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    ></b-form-datepicker>
                                    <b-form-datepicker
                                        v-else
                                        disabled
                                          class   = "form-control"
                                          locale  = "pt-BR"
                                          v-model = "formAddDe.dataMotivoRecusa"
                                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    ></b-form-datepicker>
                                    </div>
                                    <div>
                                        <div class="conteiner-icone">
                                            <i class="fa fa-exclamation-triangle icone-Aviso"></i><label class="infoLabel-motivo left" for="range-1"> Motivo do retorno</label>
                                        </div>
                                        <b-form-textarea
                                        disabled="true"
                                            v-model="formAddDe.motivoRecusa"
                                            id="textarea-default"
                                            :placeholder= "formAddDe.motivoRecusa !== null ? formAddDe.motivoRecusa : 'Sem Motivo de Reprovação'"
                                        ></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div class="data-picker-container">
                                    <b-form-checkbox v-model="ehDataRetroativa" >{{ $t('add_expenses.retroactive_date') }}</b-form-checkbox>
                                    <b-form-datepicker
                                        v-if="$i18n.locale == 'escl'"
                                        :disabled = "!ehDataRetroativa"
                                          class   = "form-control"
                                          locale  = "es-Cl"
                                          v-model = "formAddDe.dataRegistroDespesa"
                                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                          @input ="validarData($event)"
                                    ></b-form-datepicker>
                                    <b-form-datepicker
                                        v-else
                                        :disabled = "!ehDataRetroativa"
                                          class   = "form-control"
                                          locale  = "pt-BR"
                                          v-model = "formAddDe.dataRegistroDespesa"
                                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                          @input ="validarData($event)"
                                    ></b-form-datepicker>
                                </div>
                            </div>
                              <div v-if="typeExpenses === 0">
                                <label class="infoLabel" for="range-1"  >{{$t('add_expenses.category')}}</label>
                                <select v-model="formAddDe.categoria" class="form-control" :placeholder="$t('options.selectTypeExpense')" @change="verificarSubCategoria">
                                    <option disabled value="null">{{$t('options.selectTypeExpense')}}</option>
                                    <option v-for="(data, index) in $t('options.categories')" :key="index" :value="data.value">{{data.text}}</option>
                                </select>
                            <div v-if="formAddDe.categoria === 1">
                                <label class="infoLabel" for="range-1">{{ $t('add_expenses.typeCar') }}</label>
                                <select v-model="formAddDe.tipoVeiculo" class="form-control" :placeholder="$t('options.selectTypevehicle')">
                                    <option disabled value="null">{{$t('options.selectTypevehicle')}}</option>
                                    <option v-for="(data,index) in $t('options.categoriesVehicle')" :key="index" :value="data.value">{{data.text}}</option>
                                </select>
                            </div>
                            <div class="placaModelo" v-if="formAddDe.categoria === 1">
                                <div style="width:100%">
                                    <label class="infoLabel" for="range-1">{{ $t('add_expenses.licenseplate') }}</label>
                                    <input @change="validarPlaca" id="placa" type="text" v-model="formAddDe.placa"   class="form-control" name="placa" :placeholder="$t('add_expenses.placeHolderCarmodel')">
                                </div>
                                <div style="width:100%">
                                    <label class="infoLabel" for="range-1">{{ $t('add_expenses.carModel') }}</label>
                                    <input id="modelo" type="text" class="form-control" v-model="formAddDe.modelo" name="modelo" :placeholder="$t('add_expenses.placeholderModelo')">
                                </div>
                            </div>
                            <div>
                                <label class="infoLabel" for="range-1">{{ $t('add_expenses.subcategory') }}</label>
                                <select v-model="formAddDe.subCategoria" class="form-control" :placeholder="$t('options.selectTypeSubcategory')" v-if="formAddDe.categoria === 0">
                                    <option disabled value="null">{{ $t('options.selectTypeExpense') }}</option>
                                    <option v-for="(data,index) in $t('options.subcategoriestravel')" :key="index" :value="data.value">{{data.text}}</option>
                                </select>
                                <select v-model="formAddDe.subcategoriaVeiculo" class="form-control" placeholder="Selecione" v-else>
                                    <option disabled value="null">{{ $t('options.selectTypeSubcategory') }}</option>
                                    <option v-for="(data,index) in $t('options.subcategoriaVehicle')" :key="index" :value="data.value">{{data.text}}</option>
                                </select>
                            </div>
                            <div class="litroTipo" v-if="formAddDe.subcategoriaVeiculo === 0">
                                <div style="width:100%" v-if="formAddDe.categoria === 1">
                                    <label class="infoLabel" for="range-1">{{ $t('add_expenses.typefuel') }}</label>
                                    <select id="tipoCombustivel"  class="form-control" v-model="formAddDe.tipoCombustivel" name="tipoCombustivel" placeholder="Selecione">
                                        <option disabled value="null">{{ $t('options.select') }}</option>
                                        <option v-for="(data,index) in dataSubCategoriaCombustivel" :key="index" :value="data">{{data}}</option>
                                    </select>
                                </div>
                                <div style="width:100%"  v-if="formAddDe.categoria === 1">
                                    <label class="infoLabel" for="range-1">{{ $t('add_expenses.quantityFuel') }}</label>
                                    <input  id="litros" type="text" @input="validarQuantidadeDeLitros" class="form-control" name="litros" v-model="formAddDe.litros" :placeholder="$t('add_expenses.placeHolderquantityFuel')">
                                </div>
                            </div>
                            <div v-if="formAddDe.subcategoriaVeiculo === 1">
                                <div v-if="formAddDe.categoria === 1">
                                <label class="infoLabel" for="range-1">{{  $t('add_expenses.quantityOil') }}</label>
                                <input id="litrosOleo" type="text" class="form-control" name="litrosOleo" v-model="formAddDe.litrosOleo" :placeholder="$t('add_expenses.placeholderquantityOil')">
                                </div>
                            </div>
                              </div>
                              <div v-else>
                                <label class="infoLabel" for="range-1"  >{{ $t('add_expenses.category') }}</label>
                                <select v-model="formAddDe.subCategoria" class="form-control" :placeholder="$t('options.selectTypeSubcategory')" @change="verificarSubCategoria">
                                    <option disabled value="null">{{ $t('options.select') }}</option>
                                    <option v-for="(data, index) in $t('options.subcategoriesmiscellaneous')" :key="index" :value="data.value">{{data.text}}</option>
                                </select>
                              </div>
                            <div class="containerInputNumber">
                                <div class="quantidade">
                                    <div style="width:100%">
                                        <label class="infoLabel" for="range-1">{{ $t('add_expenses.quantity') }}</label>
                                        <input @change="formAddDe.quantidade = validarQuantidade($event)" id="quantidade" type="number" class="form-control" min="1" v-model="formAddDe.quantidade"  name="quantidade" placeholder="Digite a quantidade">
                                    </div>
                                </div>
                                <div class="valor">
                                    <label class="infoLabel" for="range-1">{{ $t('add_expenses.value') }}</label>
                                    <money
                                        :prefix="($i18n.locale === 'ptbr') ? 'R$' : 'CLP'"
                                        id="valor"
                                        :placeholder="'0,00'"
                                        class="form-control"
                                        style="text-align: right;"
                                        v-model="formAddDe.valor"
                                        v-validate="'required'"
                                        aria-describedby="valueErrorFeedback"
                                        v-bind="($i18n.locale === 'pt') ? $lang.Currency.Format : { style: 'currency', currency: 'CLP', currencyDisplay: 'symbol' }"
                                    ></money>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label style="margin-bottom: 5%;" class="infoLabel" for="range-1">{{ $t('add_expenses.attach_receipt') }}</label><br>

                                    <file-select class="file-container" v-model="file" @edicao="edicao" v-if="file === null"></file-select>

                                    <div class="filePreview" v-if="file != null">
                                        <file-select class="file-container-edite" @edicao="edicao" v-model="file"></file-select>
                                        <div @click="preview">
                                            <i  class="fa fa-eye fa-lg icone-olho" id="myImg" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label class="infoLabel" for="range-1">{{ $t('add_expenses.observations') }}</label>
                                <b-form-textarea
                                    v-model="formAddDe.observacao"
                                    id="textarea-default"
                                    :placeholder="$t('add_expenses.placeholderobservation')"
                                ></b-form-textarea>
                            </div>
                        </div>
                            <div class="botaoPopup">
                                <button
                                :disabled="validacaoBotaoAvancar()
                                ? DisabledDesp = true : DisabledDesp = false"
                                :class="this.DisabledDesp == true ? 'botaoAvancarDesabled' : 'botaoAvancar'"
                                @click="openModalAddDe">{{ $t('button.next') }}</button>
                            </div>
                        </div>

                    </b-modal>
                    <b-modal hide-footer ref="openModalAddDe" id="modal-2" :title="$t('informEmployee.title')">
                        <p class="my-4">{{ $t('informEmployee.description')}}</p>
                        <div>
                            <div v-for="(data, index) in formAddDe.funcionario" :key="data.id">
                                <label class="infoLabel" for="range-1">{{ $t('informEmployee.codEmployee') }} 0{{parseInt(index) + 1}}</label>
                                <div class="funcionario">
                                    <input min="0" :disabled="index === 0 ? true : false" v-model="data.codigo" :id="'codigo-' + index" @change="onChangeCodeFuncionario($event, index)"  type="number" class="form-control codigo" name="codigo" placeholder="0000">
                                    <input min="0" :disabled="index === 0 ? true : false" v-model="data.nome" :id="'nome-' + index"  @change="onChangeNameFuncionario($event, index)" type="text" class="form-control"  :placeholder="$t('informEmployee.nameEmployee')">
                                    <div v-if="index >= 1" class="remove-more-people ml-2" @click="removerfuncionario(index)" v-b-tooltip="$t('button.unbindEmplooyee')"><i class="fas fa-minus-circle"></i></div>
                                </div>
                            </div>
                            <div class="adicionarFunc">
                                <a href="#" @click="AdicionarFuncionario" class="txt1" style="color: #008643; text-decoration: underline;"><i class="fas fa-plus-circle mr-1"></i>{{ $t('button.bindEmployee') }}</a>
                            </div>
                            <div class="botaoPopup">
                                <button class="botaoVoltar" @click="VoltarDespesa">{{ $t('button.back') }}</button>
                                <button class="botaoAvancar" @click="openModalAddThird">{{ $t('button.next') }}</button>
                            </div>
                        </div>
                    </b-modal>
                    <b-modal hide-footer ref="terceiroModal" id="modal-3" :title="$t('abstract.title')">
                        <div class="containerModal">
                            <p class="my-4">{{ $t('abstract.description') }}</p>
                            <div class="grid">
                                <div>
                                    <div class="infoLabel">{{ $t('abstract.quantity') }}</div>
                                    <div class="infoConteudo">{{formAddDe.quantidade}}</div>
                                </div>
                                <div>
                                    <div class="infoLabel">{{ $t('abstract.value') }}</div>
                                    <div v-if="$i18n.locale === 'ptbr'" class="infoConteudo" >{{formAddDe.valor == null ? '' :  formAddDe.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}</div>
                                    <div v-else class="infoConteudo" >{{formAddDe.valor == null ? '' :  formAddDe.valor.toLocaleString('es-cl', { currencyDisplay: "code", minimumFractionDigits: 2, style: "currency", currency: "CLP" }) }}</div>
                                </div>
                                <div>
                                    <div class="infoLabel">{{ $t('abstract.category') }}</div>
                                    <div class="infoConteudo">{{verificaCategoriaEnum}}</div>
                                </div>
                                <div v-if="typeExpenses === 0">
                                    <div class="infoLabel">{{ $t('abstract.services') }}</div>
                                    <div class="infoConteudo">{{verificaEnum}}</div>
                                </div>
                                <div v-if="formAddDe.subcategoriaVeiculo === 0 && formAddDe.categoria === 1">
                                    <div class="infoLabel">{{ $t('abstract.quantityFuel') }}</div>
                                    <div class="infoConteudo">{{formAddDe.litros}}</div>
                                </div>
                                <div v-if="formAddDe.subcategoriaVeiculo === 0 && formAddDe.categoria === 1">
                                    <div class="infoLabel">{{ $t('abstract.typefuel') }}</div>
                                    <div class="infoConteudo">{{formAddDe.tipoCombustivel}}</div>
                                </div>
                                <div v-if="formAddDe.subcategoriaVeiculo === 1 && formAddDe.categoria === 1">
                                    <div class="infoLabel">{{ $t('abstract.quantityOil') }}</div>
                                    <div class="infoConteudo">{{formAddDe.litrosOleo}}</div>
                                </div>
                            </div>
                            <div class="resumo">
                            <div class="linhas" v-for="(data, index) in formAddDe.funcionario" :key="index">
                                <div class="infoLabel">{{ $t('abstract.codEmployee')}} {{index + 1}}</div>
                                <div class="infoConteudo">{{data.codigo}} - {{data.nome}}</div>
                            </div>
                            <div class="observacoes">
                                <div class="infoLabel">{{ $t('abstract.observations') }}</div>
                                <div class="infoConteudo">{{formAddDe.observacao}}</div>
                            </div>
                            </div>
                            <div class="botaoPopup">
                                <button class="botaoAvancar" @click="AdicionarDespesa()">{{ $t('button.add') }}</button>
                            </div>
                        </div>
                    </b-modal>
                    <b-modal hide-footer hide-header id="modal-4" :title="$t('submitExpenses.title') " ref="modalExpense">
                        <div class="header-expenses-title-container">
                            <div class="header-expenses-title">{{ $t('submitExpenses.title') }}</div>
                            <div class="header-expenses-close" @click="closeSendExpensesModal">x</div>
                        </div>
                        <div class="header-expenses">
                            <div>{{ this.typeExpenses === 0 ? $t('submitExpenses.description') : $t('submitExpenses.descriptionTecnico') }}</div>
                        </div>
                        <div class="cordinator-container">
                            <div class="tecnico-coordenador" v-if="this.typeExpenses === 0">
                                <label class="infoLabel" for="range-1">{{ $t('submitExpenses.responsible') }}</label>
                                <AutoComplete
                                    :suggestions="coordinatorArray"
                                    @complete="filterName"
                                    v-model="autocomplete"
                                    field="nome"
                                    value="id"
                                    :disabled="disabled"
                                    class="autocomplete-input"
                                    :placeholder="$t('submitExpenses.searchCoordinator')"
                                    >
                                </AutoComplete>
                            </div>
                            <div class="tecnico-coordenador" v-if="this.typeExpenses === 1">
                                <label class="infoLabel" for="range-1">{{ $t('submitExpenses.technician') }}</label>
                                <AutoComplete
                                    :suggestions="technicianArray"
                                    @complete="filterNameTechnician"
                                    v-model="autocompleteTecnico"
                                    field="nome"
                                    value="id"
                                    :disabled="disabled"
                                    class="autocomplete-input"
                                    :placeholder="$t('submitExpenses.searchTechnician')"
                                    >
                                </AutoComplete>
                            </div>

                                <label class="infoLabel" for="range-1"> {{ $t('submitExpenses.Regional') }} </label>
                                <autocomplete-z ref="obraRHTypeahead"
                                    :placeholder="$t('submitExpenses.placeholderName')"
                                    type="text"
                                    iconClass="fas fa-hard-hat"
                                    :serializer="(obra) => obra.referencia + ' - ' + obra.localizacao"
                                    :debounceLength="2"
                                    :showCode="true"
                                    :formField="formAddDe.obraId"
                                    v-on:search="onSearchObra"
                                    v-on:changeCode="onChangeCodeObra"/>
                        </div>

                        <div class="botaoPopupExpenses">
                            <button class="btnAvancarExpenses" :class="verificarSendContralodoria ? 'btnAvancarDisabled' : 'btnAvancarEnabled'"
                            :disabled="verificarSendContralodoria" @click="sendExpenses">{{ $t('button.next') }}</button>
                        </div>
                    </b-modal>
                </div>
            </div>

            <div v-for="(expense, index) in expenses" v-bind:key="index" class="consulta-data-container">
                <div class="data-container"> {{expense.dataRegistroDespesaFormatada}} </div>
                <div class="data-card-container" v-if="typeExpenses === 0">
                    <div v-for="(dataDespesa, index) in (expense.despesasViagens)"  v-bind:key="index" class="subcategoria-container">
                        <div class="titulo-container upperCase" @click="openEditarDespesa(dataDespesa)">{{dataDespesa.categoria === 0 ? dataDespesa.subCategoriaDescricao : dataDespesa.getSubCategoriaVeiculoDescricao}}</div>
                        <!-- <div class="titulo-container upperCase" @click="openEditarDespesa(dataDespesa.id)">{{ verificaCategoria() }}</div> -->
                        <div class="gasto-container-red" style="font-weight: 500">
                            <div v-if="$i18n.locale === 'ptbr'">{{`- ${dataDespesa.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}}</div>
                            <div v-else>{{` - CLP ${dataDespesa.valor.toLocaleString('es-CL', {  currency: 'CLP' , minimumFractionDigits: 2})}`}}</div>
                        </div>
                    </div>
                    <div class="totalDia-container">
                        <div class="titulo-container">{{ $t('consultation.total_daily') }}</div>
                        <div class="gasto-container-red"> - {{calTotal(expense)}} </div>
                    </div>
                </div>
                <div class="data-card-container" v-else>
                    <div v-for="(dataDespesa, index) in (expense.despesasDiversos)"  v-bind:key="index" class="subcategoria-container">
                        <div class="titulo-container upperCase" @click="openEditarDespesa(dataDespesa)"><i class="fa fa-exclamation-triangle icone-Aviso right-padd " v-if="dataDespesa.dataMotivoRecusa != null"></i>{{dataDespesa.subCategoriaDescricao}}</div>
                        <!-- <div class="titulo-container upperCase" @click="openEditarDespesa(dataDespesa.id)">{{ verificaCategoria() }}</div> -->
                        <div class="gasto-container-red" style="font-weight: 500">
                            <div v-if="$i18n.locale === 'ptbr'">{{`- ${dataDespesa.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}}</div>
                            <div v-else>{{` - CLP ${dataDespesa.valor.toLocaleString('es-CL', {  currency: 'CLP' , minimumFractionDigits: 2})}`}}</div>
                        </div>
                    </div>
                    <div class="totalDia-container">
                        <div class="titulo-container">{{ $t('consultation.total_daily') }}</div>
                        <div class="gasto-container-red"> - {{calTotal(expense)}} </div>
                    </div>
                </div>
            </div>

            <!-- Modal para arquivos -->
            <div id="myModal" class="modal">
                <div class="close"  @click="close()">&times;</div>
                <div v-if="this.tipo != null" class="Donwload-Preview" @click="downloadComprovante()"><i class="fa fa-arrow-down fa-2x" aria-hidden="true"></i></div>
                <div v-if="this.tipo != null">
                    <div class="arquivo-Previ" v-if="this.tipo === 1 || this.tipo === 0 || this.tipo === 2 || this.tipo === 3">
                        <img class="image-comprovante" :src="'data:image/png;base64,' + this.base64" />
                    </div>
                    <div class="arquivo-Previ" v-if="this.tipo === 4">
                        <div class="icone-semExtensao">
                            <i class="fa fa-file icone-file" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div id="caption">{{this.nomeArquivo}}</div>
                </div>
                <div v-if="tipo == null">
                        <div class="arquivo-Previ" id="imagemContainer"></div>
                        <div class="arquivo-Previ" v-if="tipoArquivo === true">
                            <div class="icone-semExtensao">
                                <i class="fa fa-file icone-file" aria-hidden="true"></i>
                            </div>
                        </div>
                </div>
            </div>

        </div>
</template>

<script>

import Consultas from './Consultas'
export default Consultas

</script>

<style scoped>
.tecnico-coordenador {
    display: flex;
    flex-direction: column;
}
.right-padd {
    padding-right: 7px;
}
.conteiner-icone{
    display: flex;
}
.infoLabel-motivo{
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    justify-items: center;
}
.left{
    margin-left: 10px;
}
.icone-Aviso {
    color: rgb(226, 196, 23);
    font-size: 20px;
    margin-top: 0.5rem;
}
.icone-Aviso-pendente{
    color: rgb(226, 196, 23);
    font-size: 20px;
    margin-top: 0.5rem;
}
.conteiner-motivo{
    padding: 1rem 0 1rem 0;
}
.icone-file{
    color: #044d22c7;
    font-size: 150px;
}
.icone-semExtensao{
    width: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
    border-radius: 34px;
    background-color: #fefefe;
}
.image-comprovante{
    width: 60%;
}
.arquivo-Previ{
    display: flex;
    justify-content: center;
    width: 100%;
}
.filePreview{
    display: flex;
}
.Donwload-Preview{
    display: flex;
    justify-content: right;
    position: absolute;
    top: 15px;
    right: 20px;
    color: #a5a4a4c7;
    font-size: 20px;
    font-weight: bold;
    transition: 0.3s;
}
.icone-olho{
    margin-left: 1rem;
    margin-top: 1rem;
}
#modal-2{
    overflow-y: scroll;
    height: 75vh;
}
#litros{
    text-align: right;
}
.valor{
    width: 100%;
}

:deep(.modal.show .modal-dialog) {
    transform: none;
    height: 100%;
    display: flex;
    align-items: center;
}
.grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 20%;
    row-gap: 10%;
}

.placaModelo {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.litroTipo {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap:10px;
}

#codigo{
    margin-right: 10%;
    width: 30%;
}
.quantidade{
   display:flex;
   justify-content: space-between;
    align-items: end;
    width: 100%;

}
.infoLabel{
    font-size: 13px;
    font-weight: 600;
    margin-top: 15px;
}
.scrollbar-AddDespesa {
    overflow: auto;
    height: 75%;
    overflow-y: scroll;
}
.infoConteudo{
    font-size: 13px;
    font-weight: 500;
    margin-top: 3%;
    word-break: break-word;
}
.containerModal{
    margin: 25px;
}
.primeiraLinha{
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
}
.segundaLinha{
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
}
/* .linhas{
    margin-top: 1rem;
} */
.resumo {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2.5rem;
}
.adicionarFunc{
    display: flex;
    justify-content: center;
    margin-top: 5%;
}
.funcionario{
    display: flex;
}
.codigo {
    width: 25%;
}
.botaoAvancar{
    background-color: #008643;
    height: 35px;
    font-size: 15px;
    border-radius: 25px;
    color: white;
    margin: 2%;
    width: 70%;
    border: none;
}
.moda-compl{
    height: 75vh;
}
.botaoAvancarDesabled{
    background-color: #949494;;
    height: 35px;
    font-size: 15px;
    border-radius: 25px;
    color: white;
    margin: 2%;
    width: 70%;
    border: none;
}
.btnAvancarExpenses{
    height: 35px;
    font-size: 15px;
    border-radius: 25px;
    color: white;
    margin: 2%;
    width: 70%;
    border: none;
    background-color: #949494;
}

.btnAvancarDisabled{
    background-color: #949494;
}

.btnAvancarEnabled{
    background-color: #008643;
}

.botaoVoltar{
    background-color: #868686;
    height: 35px;
    font-size: 15px;
    border-radius: 25px;
    color: white;
    margin: 2%;
    width: 70%;
    border: none;
}

.botaoPopup{
    display: flex;
    justify-content: center;
    margin-top: 15%;
}

.botaoPopupExpenses{
    display: flex;
    justify-content: center;
    margin-top: 10%;
}

.containerInputNumber{
    display: flex;
    justify-content: space-between;
    gap:10px;
}

.btn, .btn-add, .btn-send{
    background-color: #ffffff; /* Blue background */
    border: none; /* Remove borders */
    color: rgb(148, 148, 148); /* White text */
    padding: 6px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
    cursor: pointer; /* Mouse pointer on hover */
    background-size: cover;
    width: 51px;
    height: 40px;
}

.btn-add{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='36' viewBox='0 0 46 36'%3E%3Cg id='Bot%C3%A3o_adicionar' data-name='Bot%C3%A3o adicionar' transform='translate(-304 -275)'%3E%3Cg id='Ret%C3%A2ngulo_24457' data-name='Ret%C3%A2ngulo 24457' transform='translate(304 275)' fill='none' stroke='%23008148' stroke-width='1'%3E%3Crect width='46' height='36' rx='5' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='45' height='35' rx='4.5' fill='none'/%3E%3C/g%3E%3Cpath id='icone_adicionar' data-name='icone adicionar' d='M13.408 3.375A10.033 10.033 0 1 0 23.441 13.408 10.031 10.031 0 0 0 13.408 3.375Zm4.365 10.8H14.18v3.594a.772.772 0 0 1-1.544 0V14.18H9.043a.772.772 0 0 1 0-1.544h3.594V9.043a.772.772 0 0 1 1.544 0v3.594h3.594a.772.772 0 1 1 0 1.544Z' transform='translate(313.592 279.592)' fill='%23008148'/%3E%3C/g%3E%3C/svg%3E");
}

.btn-send{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='36' viewBox='0 0 46 36'%3E%3Cg id='Bot%C3%A3o_enviar' data-name='Bot%C3%A3o enviar' transform='translate(-363 -275)'%3E%3Crect id='Ret%C3%A2ngulo_24452' data-name='Ret%C3%A2ngulo 24452' width='46' height='36' rx='5' transform='translate(363 275)' fill='%23008148'/%3E%3Cpath id='icon_enviar' data-name='icon enviar' d='M23.194 4.546 4.742 12.588a.423.423 0 0 0 .015.769l4.991 2.82a.8.8 0 0 0 .92-.09L20.51 7.6c.065-.055.221-.161.281-.1s-.035.216-.09.281l-8.515 9.59a.8.8 0 0 0-.08.96l3.262 5.232a.424.424 0 0 0 .764-.01L23.762 5.1A.423.423 0 0 0 23.194 4.546Z' transform='translate(374.235 276.809) rotate(9)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}

.cordinator-container {
    display: flex;
    flex-direction: column;
}

.progress-bar{
    width: 100%;
    height: 10px;
    border-radius: 4px;
    background-color: darkgrey;
}
.progress-barToSend{
    width: 100%;
    height: 10px;
    border-radius: 4px;
    background-color: transparent;
}

:deep(.p-progressbar .p-progressbar-value) {
    border: 0 none;
    margin: 0;
    /* background: #008148; */
    background: v-bind(progressBarColor);
    border-radius: 4px;
}
.valueToSend {
    color: v-bind(progressBarColorText);
    font-weight: 600;
    font-size: 12px;
}

.saldo-card {
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    box-shadow: 1px 1px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.saldo-card-title {
    font-size: 14px;
    font-weight: 600;
}

.used-available-container{
    display: flex;
    flex-direction: column;
}

.formatedProgressText {
    font-weight: 600;
    font-size: 12px;
}
.formatedProgressBalanceAvailable{
    font-weight: 600;
    font-size: 12px;
    color:v-bind(progressBarColorText);
}
.formatedProgressTextDetails {
    font-weight: 600;
    font-size: 12px;
    color: #de852c;
}

.data-container{
    font-size: 14px;
    font-weight: 600;
    margin: 20px auto;
}
.data-picker-container{
    font-size: 14px;
    font-weight: 600;
    display:flex;
    flex-direction: column;
    gap:10px;
   align-items: flex-start;
}
.data-card-container {
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 1px 1px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.saldo-card-info{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.saldo-card-info-details{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column
}

.refeicao-container, .hospedagem-container, .totalDia-container, .saldoDia-container, .subcategoria-container {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #70707011;
}

.titulo-container {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
}

.upperCase{
    text-transform: uppercase;
    cursor: pointer;
}

.gasto-container, .gasto-container-red, .gasto-container-green {
    font-size: 14px;
    font-weight: 600;
}

.gasto-container-red {
    display: flex;
    color: #E53939;
    gap: 8px;

}

.gasto-container-green{
    color: #008148;
}

.saldoDia-container{
    color: #008148;
}

.lancamentos-container {
    margin-top: 15px;
}

.lancamentos-header{
    display: flex;
    justify-content: space-between;
}

.lancamentos-search-container{
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 6px;
    width: 60%;
    padding: 0 10px;
    gap: 0.5rem;
}

.icon-background {
    font-size: 16px;
    color: #666666ad;
    width: 18px;
    border-right: 1px solid #6666666e;
    padding-right: 5px;
}

.search-input {
    border: none;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    height: 35px;
    background: transparent;
    outline: none;
}

.header-expenses {
    display: flex;
    justify-content: center;
    text-align: center;
}

:deep(.p-autocomplete-input.p-inputtext.p-component) {
    width: 100%;
}

.header-expenses-title-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.8rem;
    align-items: center;
}

.header-expenses-title{
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1.25rem;
}
.header-expenses-close{
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    background-color: transparent;
    border: 0;
    transform: rotateX(39deg);
    font-size: 1.4rem;
}

:deep(label.file-select.file-container) {
    width: 100%;
}

:deep(label.file-select.file-container-edite) {
    width: 90%;
}

:deep(.select-button .selecionado){
    display: block;
    padding: 0.6rem 2.4rem !important;
}
:deep(.select-button .nao-selecionado) {
    display: block;
}

.form-filtro{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ffffff;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-filtro:focus:not(.focus-visible) {
  outline: 0;
  box-shadow: none;
  border: none;
}

/* Modal arquivos editar*/
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {opacity: 0.7;}

.modal {
  display: none;
  position: fixed;
  padding: 25px;
  z-index: 9999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

.modal-content, #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.fa-circle-info {
    scale: 1.5;
    color:red;
}

.fa-clock {
    scale: 1.5;
    color:#de852c;
}
@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)}
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

.close {
  position: absolute;
  top: 8px;
  right: 70px;
  color: #ffffff;
  font-size: 60px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #ffffff;;
  text-decoration: none;
  cursor: pointer;
}

.Donwload-Preview:hover,
.Donwload-Preview:hover {
  color: #ffffff;;
  text-decoration: none;
  cursor: pointer;
}

.remove-more-people{
    color: red;
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
</style>
