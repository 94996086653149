import toastr from 'toastr'

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-center',
  preventDuplicates: true,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
}

const configure = () => toastr.options

// const configure = () => {
//   // toastr.options.closeMethod = 'slideUp';
//   // toastr.options.closeDuration = 3000;
//   // toastr.options.closeEasing = 'swing';

//   // toastr.options.newestOnTop = true;

//   // Define a callback for when the toast is shown/hidden/clicked
//   // toastr.options.onShown = () => { };
//   // toastr.options.onHidden = () => { };
//   // toastr.options.onclick = () => { };
//   // toastr.options.onCloseClick = () => { };

//   // toastr.options.escapeHtml = true;
//   // toastr.options.closeHtml = '<button><i class="icon-off"></i></button>';
//   toastr.options.closeButton = true

//   // Animations
//   // toastr.options.showEasing = 'swing';
//   // toastr.options.hideEasing = 'swing';
//   // toastr.options.closeEasing = 'linear';

//   // Animation easings
//   // toastr.options.showEasing = 'easeOutBounce';
//   // toastr.options.hideEasing = 'easeInBack';
//   // toastr.options.closeEasing = 'easeInBack';

//   // Animation methods
//   toastr.options.showMethod = 'slideDown'
//   // toastr.options.hideMethod = 'slideUp';
//   // toastr.options.closeMethod = 'slideUp';

//   // Prevent duplicates
//   toastr.options.preventDuplicates = true

//   // Progress Bar
//   toastr.options.progressBar = true
//   // How long the toast will display without user interaction
//   // toastr.options.timeOut = 5;
//   // How long the toast will display after a user hovers over it
//   // toastr.options.extendedTimeOut = 60;
//   // toastr.options.rtl = true;
// }

const Install = () => {
  configure()
}

export default {
  Install
}
