<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.TravelExpensesReleases.LabelTitle }}</span>
    </div>
    <div class="text-center py-2">
      <button
        type="button"
        :disabled="!despesa || !despesa.id"
        class="btn btn-third text-bold px-3 py-2"
        @click="onAddExpense"
      >
        <i class="fas fa-plus-circle"/>&nbsp;{{ $lang.TravelExpensesReleases.ButtonAdd }}
      </button>
    </div>
    <div class="w-100 overflow-x-auto">
      <table class="table table-hover" style="border: 1px solid #dcdede;">
              <thead class="thead-third" style="border: solid 1px #0277bd; height: 44px;">
                <tr>
                  <th class="text-center">{{$lang.TravelExpensesReleases.DataTable.Release}}</th>
                  <th class="text-center">{{$lang.TravelExpensesReleases.DataTable.Date}}</th>
                  <th class="text-center">{{$lang.TravelExpensesReleases.DataTable.Value}}</th>
                  <th class="text-center">{{$lang.TravelExpensesReleases.DataTable.Balance}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="releases.length === 0">
                  <td colspan="4" class="text-center">
                    {{$lang.Common.DataTable.NoRecordsFound}}
                  </td>
                </tr>
                <tr v-else v-for="(release, index) in releases" v-bind:key="release.id">
                  <td class="text-center">
                    {{ despesaTipoNome(release.despesaTipoID) }}
                  </td>
                  <td class="text-center">
                    {{moment(release.dataDespesa).format('DD/MM/yyyy')}}
                  </td>
                  <td class="text-center text-danger">
                    <div
                      v-if="release.forma === 0"
                      style="min-width: 70px;"
                    >
                      -{{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(release.valorTotal) }}
                    </div>
                    <div
                      v-else
                      style="color: #034EB4; min-width: 70px;"
                    >
                      {{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(release.valorTotal) }}
                    </div>
                  </td>
                  <td class="text-center" style="color: #034EB4;">
                    {{ new Intl.NumberFormat($lang.Current, { style: 'currency', currency: $lang.Currency.Name}).format(onGetBalance(index)) }}
                  </td>
                </tr>
              </tbody>
            </table>
    </div>
    <div class="text-center py-2">
      <button
        type="button"
        :disabled="!despesa || !despesa.id"
        class="btn btn-third text-bold px-3 py-2"
        @click="onFinish"
      >
        <i class="fas fa-plus-circle"/>&nbsp;{{ $lang.TravelExpensesReleases.ButtonFinish }}
      </button>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'TravelExpensesReleases',
  mixins: [
    ActionForms
  ],
  props: {
    despesa: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      area: 2,
      releases: [],
      types: []
    }
  },
  watch: {
    'despesa.id': function (value) {
      this.onGetDespesaItens(value)
    }
  },
  mounted () {
    this.onGetDespesaItens(this.despesa.id)
    this.onGetDespesasTipos()
  },
  methods: {
    onAddExpense () {
      this.$router.push({ name: 'TravelExpensesType', params: { despesa: this.despesa } })
    },
    onFinish () {
      this.$database.businessCommon.despesaRepository.Upsert({
        ...this.despesa,
        dataAcao: this.moment().format('YYYY-MM-DD hh:mm:ss'),
        situacao: 1
      }, success => {
        this.$database.businessCommon.despesaRepository.Sync(success => {
          console.log(success)
        }, error => {
          console.log(error)
        })

        this.toast.success(this.$lang.Common.Alerts.Success)
        this.$router.push({ name: 'TravelExpensesMenu' })
      })
    },
    onGetDespesaItens (value) {
      if (value) {
        this.loading.push()
        this.$database.businessCommon.despesaItemRepository.GetAllByDespesa({ inquilinoID: this.accountLogged.InquilinoID, despesaID: value }, { sort: { dataDespesa: 1 } })
          .fetch(data => {
            this.releases = data
            this.loading.pop()
          }, error => {
            console.log(error)
            this.loading.pop()
          })
      }
    },
    onGetDespesasTipos () {
      this.$database.businessCommon.despesaTipoRepository.GetAllByDiversos({}, {})
        .fetch(data => {
          this.types = data
          this.loading.pop()
        }, error => {
          console.log(error)
          this.types = []
          this.loading.pop()
        })
    },
    onGetBalance (index) {
      const releases = this.releases

      let balance = 0

      for (let i = 0; i <= index; i++) {
        const release = releases[i]

        if (release.forma === 1) {
          balance += release.valorTotal
        } else {
          balance -= release.valorTotal
        }
      }

      return balance
    },
    despesaTipoNome (despesaTipoID) {
      return this.types.find(d => d.id === despesaTipoID).nome || 'N/D'
    }
  }
}
</script>
