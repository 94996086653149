<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.MiscExpensesConfirmation.LabelTitle }}</span>
    </div>
    <div v-if="loaded" class="card py-4 w-100">
      <!-- Confirmação -->
      <div v-if="step === 'Confirmation'" class="card-body p-4 d-flex flex-column align-items-center">
        <h5 class="text-third text-bold">{{ $lang.MiscExpensesConfirmation.LabelConfirm }}</h5>
        <div class="text-center align-self-center mt-2">
          <button
            type="button"
            class="btn btn-danger text-bold mx-1"
            @click="onNoConfirmation"
          >
            <i class="fas fa-times-circle"/>&nbsp;{{ $lang.MiscExpensesConfirmation.ButtonNo }}
          </button>
          <button
            type="button"
            class="btn btn-third text-bold mx-1"
            @click="onYesConfirmation"
          >
            <i class="fas fa-check-circle"/>&nbsp;{{ $lang.MiscExpensesConfirmation.ButtonYes }}
          </button>
        </div>
      </div>
      <!-- Verifica se vai realizar mais lançamentos na mesma obra -->
      <div v-if="step === 'SameConstruction'" class="card-body p-4 d-flex flex-column align-items-center">
        <h5 class="text-third text-bold">{{ $lang.MiscExpensesConfirmation.LabelSameConstruction }}</h5>
        <div class="text-center align-self-center mt-2">
          <button
            type="button"
            class="btn btn-danger text-bold mx-1"
            @click="onNoSameConstruction"
          >
            <i class="fas fa-times-circle"/>&nbsp;{{ $lang.MiscExpensesConfirmation.ButtonNo }}
          </button>
          <button
            type="button"
            class="btn btn-third text-bold mx-1"
            @click="onYesSameConstruction"
          >
            <i class="fas fa-check-circle"/>&nbsp;{{ $lang.MiscExpensesConfirmation.ButtonYes }}
          </button>
        </div>
      </div>
      <!-- Informa a obra -->
      <div v-if="step === 'InformConstruction'" class="card-body p-4 d-flex flex-column align-items-center">
        <h5 class="text-third text-bold">{{ $lang.MiscExpensesConfirmation.LabelInformConstruction }}</h5>
        <b-card-text class="w-100 my-3">
          <b-form-textarea
            id="newConstruction"
            name="construction"
            v-model="newConstruction"
            required
            autofocus
            rows="2"
            no-resize
          />
        </b-card-text>
        <div class="text-center align-self-center mt-2">
          <button
            type="button"
            class="btn btn-third text-bold mx-1"
            @click="onInformNewConstruction"
          >
            <i class="fas fa-check-circle"/>&nbsp;{{ $lang.MiscExpensesConfirmation.ButtonConfirm }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'AddConfirmation',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      step: 'Confirmation',
      loaded: true,
      newConstruction: ''
    }
  },
  computed: {
    despesa: function () {
      return this.$route.params.despesa
    }
  },
  methods: {
    back () {
      this.$router.push({ name: 'MiscExpensesReleases' })
    },
    onNoConfirmation () {
      this.back()
    },
    onYesConfirmation () {
      this.loaded = false
      this.step = 'SameConstruction'
      this.$nextTick(() => {
        this.loaded = true
      })
    },
    onNoSameConstruction () {
      this.loaded = false
      this.step = 'InformConstruction'
      this.$nextTick(() => {
        this.loaded = true
      })
    },
    onYesSameConstruction () {
      this.onFinish()
      this.$router.push({ name: 'MiscExpensesMenu' })
    },
    onInformNewConstruction () {
      this.onFinish(this.newConstruction)
      this.$router.push({ name: 'MiscExpensesMenu' })
    },
    onFinish (novaObra = '') {
      this.loading.push()

      this.$database.businessCommon.despesaRepository.Upsert({
        ...this.despesa,
        novaObra,
        dataAcao: this.moment().format('YYYY-MM-DD hh:mm:ss'),
        situacao: 1,
        _id: this.despesa.id
      }, success => {
        this.$database.businessCommon.despesaRepository.Sync(success => {
          console.log(success)
        }, error => {
          console.log(error)
        })

        this.toast.success(this.$lang.Common.Alerts.Success)
        this.$router.push({ name: 'MiscExpensesMenu' })
        this.loading.pop()
      })
    }
  }
}
</script>
