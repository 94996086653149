<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.ReimbursementType.LabelTitle }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.ReimbursementType.LabelEnterExpenseType }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-select
            id="type"
            name="type"
            v-model="type"
            data-vv-as="type"
            v-validate="'required'"
            aria-describedby="typeErrorFeedback"
            :state="errors.has('type') ? false : getValidationState('type')"
            :options="types"
          >
            <template #first>
              <option :value="null" disabled>
                {{ $lang.Common.Select }}
              </option>
            </template>
          </b-form-select>
        </b-card-text>
        <div class="text-center align-self-end">
          <button type="button" class="btn btn-third text-bold" @click="onAdd"><i class="fas fa-plus-circle"/>&nbsp;{{ $lang.ReimbursementType.ButtonAdd }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'ReimbursementType',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      type: null,
      types: []
    }
  },
  methods: {
    onLoad () {
      this.onLoadTipoDespesa()
    },
    onLoadTipoDespesa () {
      this.$database.businessCommon.despesaTipoRepository.GetAllByDiversos({ inquilinoID: this.accountLogged.InquilinoID, exibirEmDiversos: true, forma: 0 }, {})
        .fetch(data => {
          this.types = data.map(tipo => {
            return {
              text: tipo.nome,
              value: tipo
            }
          })
          this.loading.pop()
        }, error => {
          console.log(error)
          this.loading.pop()
        })
    },
    onAdd () {
      this.$validator.validateAll()
        .then(valid => {
          if (valid) {
            this.$router.push({ name: 'ReimbursementExpenseDate', params: { type: this.type } })
          } else {
            this.toast.error(this.$lang.ReimbursementType.Errors.ExpenseTypeRequired)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
