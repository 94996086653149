import { database } from '../config'

let LocalDB
const resourceName = 'conta'

const Get = (
  selector = {},
  options = {},
  success = () => {},
  error = () => {}
) => database(`${selector.id}`, resourceName, LocalDB)[resourceName].findOne(selector, options, success, error)

const ContaRepository = (localDB) => {
  LocalDB = localDB
  return {
    Get
  }
}

export default ContaRepository
