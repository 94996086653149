const SET_ACCOUNT_LOGGED = 'setAccountLogged'
const SET_COMPANY = 'setCompany'
const CLEAR_COMPANY = 'clearCompany'
const SET_TYPEEXPENSES = 'SetTypeExpenses'

export const mutationTypes = {
  SET_ACCOUNT_LOGGED,
  SET_COMPANY,
  CLEAR_COMPANY,
  SET_TYPEEXPENSES
}

export const mutations = {
  [SET_ACCOUNT_LOGGED] (state, payload) {
    state.accountLogged = payload
  },
  [SET_TYPEEXPENSES] (state, payload) {
    state.typeExpenses = payload
    var CryptoJS = require('crypto-js')
    var data = {
      typeExpenses: payload
    }
    const obj = CryptoJS.AES.encrypt(JSON.stringify(data), 'z@pMvoe').toString()
    localStorage.setItem('typeExpenses', obj)
  },

  [SET_COMPANY] (state, payload) {
    state.company.companyID = payload.companyID
    state.company.name = payload.name
    state.company.zCode = payload.zCode

    localStorage.setItem('company', JSON.stringify(payload))
  },

  [CLEAR_COMPANY] (state) {
    state.company.companyID = null
    state.company.name = null
    state.company.zCode = null

    localStorage.removeItem('workspace')
  }
}
