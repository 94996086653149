import APIClient from '@/shared/http/clients/api'

const resourceName = 'conta'
const APIAccess = process.env.VUE_APP_Core

const Get = (countryId, config = {}) => APIClient.get(`${APIAccess}/${resourceName}`, config)
const GetEmpresaLocalidade = (countryId, config = {}) => APIClient.get(`${APIAccess}/${resourceName}`, config)
const GetLogged = (config = {}) => APIClient.get(`${APIAccess}/${resourceName}/logged`, config)
const GetById = (id, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/${id}`, config)
const GetUserCompanies = (username, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/empresas?username=${username}`, config)
const GetContaEmpresaAndEmpresas = (username, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/GetContaEmpresaAndEmpresa?username=${username}`, config)
// const ResetEmailSenha = (username, config = {}) => APIClient.post(`${APIAccess}/${resourceName}/username/redefinir?username=${username}`, config)
const GetResetSenha = (username, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/GetResetSenha?username=${username}`, config)

const AccountRepository = {
  Get,
  GetLogged,
  GetById,
  GetUserCompanies,
  GetContaEmpresaAndEmpresas,
  GetResetSenha,
  GetEmpresaLocalidade
}

export default AccountRepository
