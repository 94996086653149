<template>
  <div class="d-flex flex-grow-1 flex-column align-items-center">
    <div class="d-flex align-items-center py-4">
      <i class="fas fa-file-invoice-dollar" style="font-size: 24px;"/>
      <span class="ml-2 text-bold" style="font-size: 16px;">{{ $lang.ReimbursementAuthorization.LabelTitle }}</span>
    </div>
    <div class="card py-4 w-100">
      <div class="card-body p-4 d-flex flex-column align-items-center">
        <div class="text-third text-bold">{{ $lang.ReimbursementAuthorization.Regional.LabelDescription }}</div>
        <b-card-text class="w-100 my-3">
          <b-form-select
            id="regionalID"
            name="regionalID"
            v-model="regionalID"
            :options="regionals"
            data-vv-as="regionalID"
            v-validate="'required'"
            aria-describedby="regionalIDErrorFeedback"
          >
            <template #first>
              <option :value="null" disabled>
                {{ $lang.Common.Select }}
              </option>
            </template>
          </b-form-select>
        </b-card-text>
        <div class="text-center align-self-end">
          <button
            type="button"
            class="btn btn-third text-bold"
            @click="onAdd"
          >
            <i class="fas fa-plus-circle"/>&nbsp;{{ $lang.ReimbursementAuthorization.Regional.ButtonAdd }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionForms from '@/shared/mixins/actionsForm.js'

export default {
  name: 'ReimbursementAuthorizationRegional',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      regionals: [],
      regionalID: null
    }
  },
  mounted () {
    this.onLoadAllRegionals()
  },
  methods: {
    onAdd () {
      if (this.regionalID) {
        this.$router.push({ name: 'ReimbursementAuthorizationResponsable', params: { ...this.$route.params, secaoRHID: this.regionalID } })
      } else {
        this.toast.error(this.$lang.ReimbursementAuthorization.Regional.Errors.RegionalRequired)
      }
    },
    onLoadAllRegionals () {
      this.$database.businessCommon.secaoRHRepository.GetAll({ empresaID: this.company.companyID }, {})
        .fetch(data => {
          this.regionals = data.map(r => {
            return {
              text: `${r.codigo} - ${r.nome}`,
              value: r.id
            }
          })
          this.loading.pop()
        }, error => {
          console.log(error)
          this.loading.pop()
        })
    }
  }
}
</script>
