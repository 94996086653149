<template>
  <div v-if="isConnected" id="app">
    <transition name="fade">
      <loading v-show="isLoading"></loading>
    </transition>
    <transition name="fade">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DatabaseMixin from '@/database/mixin.js'
import CommonMixin from '@/store/module/common/mixin'
import Loading from '@/components/UI/Loading'
import { globalActions as authActions } from '@/store/module/auth/actionTypes'
import { LocaleService } from './shared/services/locale'

export default {
  name: 'app',
  mixins: [
    CommonMixin,
    DatabaseMixin
  ],
  components: {
    [Loading.name]: Loading
  },
  data () {
    return {
      online: navigator.onLine,
      timers: []
    }
  },
  created () {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.showUpdateUI = true
      })
    }
  },
  beforeMount () {
    this.getCompany()
    this.getyypeexpenses()
    this.setAccountLogged()
  },
  mounted () {
    // this.fetchLoggedInUserInfo(this.$database.core.contaRepository)
    window.addEventListener('online', this.onChange)
    window.addEventListener('offline', this.onChange)
    this.$i18n.locale = LocaleService.GetLocale()
    this.timers.push(setInterval(function () {
      if (this.online) {
        this.$database.businessCommon.sync()
      }
    }, 60000))
  },
  beforeDestroy () {
    window.removeEventListener('online', this.onChange)
    window.removeEventListener('offline', this.onChange)
  },
  methods: {
    ...mapActions({
      fetchLoggedInUserInfo: authActions.FETCH_USER_INFO
    }),
    async accept () {
      this.showUpdateUI = false
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
    },
    async onChange () {
      this.online = navigator.onLine

      if (this.online) {
        this.$database.businessCommon.sync()
      }
    }
  }
}
</script>

<style lang="scss">
@import "./scss/style.scss";
</style>
