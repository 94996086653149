import APIClient from '../../../../shared/http/clients/api'

const resourceName = 'midiadespesa'
const APIBusinessCommon = process.env.VUE_APP_BusinessCommon

const ArquivoByDespesaId = (despesasId, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetByDespesaId/${despesasId}`, data, config)

const MediaExpenseRepository = {
  ArquivoByDespesaId
}

export default MediaExpenseRepository
