import { localDB as businessCommonLocal } from './businesscommon/config'
import BusinessCommonRepository from './businesscommon'

import { localDB as coreLocal } from './core/config'
import CoreRepository from './core'

export default {
  core: CoreRepository(coreLocal),
  businessCommon: BusinessCommonRepository(businessCommonLocal)
}
