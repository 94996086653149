<template>
    <div class="profile-consultas-container">
      <div>
          <h4 class="profile-title">{{ $t('profile.title') }}</h4>
          <div class="containerInputNumber">
            <div class="nome">
              <label class="infoLabel" for="range-1">{{ $t('profile.name') }}: </label>
              <input id="codigo" nome="nome" v-model="nome" disabled>
            </div>
            <div class="codigo">
              <label class="infoLabel" for="range-1">{{ $t('profile.code') }}:</label>
              <input id="codigo" nome="codigo" v-model="codigo" disabled>
            </div>
            <div class="funcao">
              <label class="infoLabel" for="range-1">{{ $t('profile.position') }}:</label>
              <input id="codigo" name="funcao" v-model="funcao" disabled>
            </div>
            <!-- <div class="idioma mt-4">
              <label class="infoLabel" for="range-1"> {{ $t('profile.language') }}:</label>
              <b-dropdown variant="link" no-caret class="px-2" v-b-tooltip.hover :title=" $t('profile.language') ">
                  <template #button-content>
                    <img class="flags" src="https://static.zopone.com.br/img/flags/Brazil.png" alt="">
                    <img class="flags" src="https://static.zopone.com.br/img/flags/Spain.png" alt="">
                  </template>
                  <b-dropdown-item href="#">
                    <b-img class="flags mr-2" src="https://static.zopone.com.br/img/flags/Brazil.png" alt="Português (Brasil)"/> Português (Brasil)
                  </b-dropdown-item>
                  <b-dropdown-item href="#">
                    <b-img class="flags mr-2"  src="https://static.zopone.com.br/img/flags/Spain.png" alt="Espanhol"/> Español
                  </b-dropdown-item>
                </b-dropdown>
            </div> -->
          </div>
          <div class="Voltar">
            <button class="botaoVoltar" @click="VoltarConsultas()">
              <i class="fa fa-undo" style="color:white" aria-hidden="true"></i>
              {{ $t('button.back') }}
            </button>
          </div>
      </div>
    </div>
</template>

<script>

import Profile from './Profile'
export default Profile

</script>

<style scoped>

.infoLabel {
  display: flex;
}

.profile-title{
  display: flex;
  justify-content: space-around;
}

.codigo{
  display: flex;
  text-align: left;
  width: auto;
  height: auto;
  flex-direction: column;
  padding-top: 1rem;
}

#codigo{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.infoLabel{
  font-size: 18px;
  height: 1rem;
  font: sans-serif;
  font-size: 13px;
  color: #666;
  font-weight: 600;
}

.funcao{
  padding-top: 1rem;
}

.Voltar{
  display: flex;
  justify-content: center;
  height: 5rem;
  align-items: center;
}

.botaoVoltar{
    background-color: #008643;
    height: 35px;
    font-size: 15px;
    border-radius: 25px;
    color: white;
    margin: 2%;
    width: 70%;
    border: none;
}

</style>
