import { database } from '../config'

let LocalDB
const resourceName = 'obrarh'

const GetByCode = (
  selector = {},
  options = {},
  success = () => {},
  error = () => {}
) => database(`empresa/${selector.empresaID}/codigo/${selector.codigo}`, resourceName, LocalDB)[resourceName].findOne(selector, options, success, error)

const GetAllByName = (
  selector = {},
  options = {}
) => {
  let nome = selector.nome
  nome = nome.$regex

  return database(`empresa/${selector.empresaID}/nome/${encodeURIComponent(nome)}?limit=${options.limit}&index=1`, resourceName, LocalDB)[resourceName].find(selector, options)
}

const ObraRHRepository = (localDB) => {
  LocalDB = localDB
  return {
    GetByCode,
    GetAllByName
  }
}

export default ObraRHRepository
