import APIClient from '@/shared/http/clients/api'

const resourceName = 'obra'
const APIBusinessCommon = process.env.VUE_APP_BusinessCommon

const GetAddress = (obraID, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/EnderecoObra/${obraID}`, data, config)
const UpdateAddress = (obraID, data = {}, config = {}) => APIClient.put(`${APIBusinessCommon}/${resourceName}/EnderecoObra/${obraID}`, data, config)
const GetAllByName = (companyID, name, limit, index, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/empresaobra/${companyID}/texto/${name}?limit=${limit}&index=${index}`, data, config)
const GetAllByCode = (companyID, code, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/empresa/${companyID}/referencia/${code}`, data, config)
const GetById = (id, data = {}, config = {}) => APIClient.get(`${APIBusinessCommon}/${resourceName}/GetById/${id}`, data, config)

const ObraRepository = {
  GetAllByName,
  GetAllByCode,
  GetAddress,
  UpdateAddress,
  GetById
}

export default ObraRepository
