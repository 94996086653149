<template>
<div v-bind:class="Fullscreen">
  <div class="row">
    <div class="col-12">
      <div class="card card-bottom-radius-8">
        <div class="card-header text-third" v-if="showHeader">
          <small v-if="showPaging">{{$lang.Common.DataTable.RecordsShow}}</small>
          <select class="mb-2 mb-sm-0" style="border: solid 1px #d9d9d9;" v-model="cbxItemPerPage" v-on:change="ReloadCountPage()" v-if="showPaging">
            <option value="5" selected>5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <small v-if="showPaging">{{$lang.Common.DataTable.Records}}</small>
          <div class="card-actions">
            <a href="javascript:void(0);" v-if="showNew" v-b-tooltip.hover :title="$lang.Common.Buttons.New" v-on:click="New">
                <i class="fas fa-plus-circle text-third" style="font-size: 15px"></i>
              </a>
              <a href="javascript:void(0);" v-if="showReload" v-b-tooltip.hover :title="$lang.Common.Buttons.Reload" v-on:click="Reload">
                <i class="fas fa-sync-alt text-third" style="font-size: 15px"></i>
              </a>
              <a href="javascript:void(0);" v-if="showSearch" v-b-tooltip.hover :title="$lang.Common.Buttons.Search" v-on:click="Search">
                <i class="fas fa-search text-third" style="font-size: 15px"></i>
                <!--<i class="icon-magnifier text-third" style="font-size: 15px"></i>-->
              </a>
              <a href="javascript:void(0);" class="d-md-down-none" v-if="showPrint" v-b-tooltip.hover :title="$lang.Common.Buttons.Print" v-on:click="Print">
                <i class="fas fa-print text-third" style="font-size: 15px"></i>
                <!--<i class="icon-printer text-third" style="font-size: 15px"></i>-->
              </a>
              <a href="javascript:void(0);" class="d-md-down-none" v-if="showDownload" v-b-tooltip.hover :title="$lang.Common.Buttons.Download" v-on:click="SaveFile">
                <i class="fas fa-cloud-download-alt text-third" style="font-size: 15px"></i>
                <!--<i class="icon-cloud-download text-third" style="font-size: 15px"></i>-->
              </a>
              <a href="javascript:void(0);" class="d-md-down-none" v-if="showFullscreen" v-on:click="Fullscreen">
                <i class="fas fa-expand-arrows-alt text-third" style="font-size: 15px" v-if="setFullscreen" v-b-tooltip.hover :title="$lang.Common.Buttons.Fullscreen"></i>
                <i class="fas fa-compress-arrows-alt text-third" style="font-size: 15px" v-if="!setFullscreen" v-b-tooltip.hover :title="$lang.Common.Buttons.ExitFullscreen"></i>
                <!--
                <i class="icon-size-fullscreen text-third" style="font-size: 15px" v-if="setFullscreen" v-b-tooltip.hover :title="$lang.Common.Buttons.Fullscreen"></i>
                <i class="icon-size-actual text-third" style="font-size: 15px" v-if="!setFullscreen" v-b-tooltip.hover :title="$lang.Common.Buttons.ExitFullscreen"> </i>
                -->
              </a>
              <a href="javascript:void(0);" class="d-md-down-none" v-if="showClose" v-on:click="$emit('Closed')" v-b-tooltip.hover :title="'Fechar'">
                <i class="fa fa-times-circle" style="font-size: 15px"></i>
              </a>
          </div>
        </div>
        <div v-bind:class="[ showHeader ? 'card-block table-responsive' : 'table-responsive' ]">
          <table class="table table-hover" v-bind:style="[ showHeader ? 'border: 1px solid #dcdede;' : '' ]">
            <thead class="thead-third" style="border: solid 1px #0277bd; height: 44px;">
              <tr>
                <th class="text-center" v-for="colTitle in columns" v-bind:key="colTitle">{{colTitle}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="total <= 0 && !loading && showPaging">
                <td :colspan="columns.length" class="text-center">{{$lang.Common.DataTable.NoRecordsFound}}</td>
              </tr>
              <tr v-if="loading">
                <td :colspan="columns.length" class="text-center" style="padding: 74px 0px 74px">
                  <h3 class="text-secundary">{{$lang.Common.Loading}}!</h3>
                  <img :src="ImgLoading" alt="Loading" style="width: 32px;"/>
                </td>
              </tr>
              <slot></slot>
            </tbody>
          </table>
          <nav aria-label="Page navigation example" v-if="showPaging">
            <ul class="pagination pagination-sm pt-1">
              <li class="page-item" v-bind:class="ActivePrevious">
                <a class="page-link" href="javascript:void(0);" tabindex="-1" v-on:click="PagingPrevious()">{{$lang.Common.DataTable.Previous}}</a>
              </li>
              <li class="page-item" v-for="index in (totalPages)" v-bind:key="index" v-bind:class="ActivePage(index)" v-on:click="LoadPage(index)" v-if="(index > 0) && (index >= currentPage - 4) && (index <= currentPage + 4)">
                <a v-if="index < 10" class="page-link" href="javascript:void(0)">0{{ index }}</a>
                <a v-else class="page-link" href="javascript:void(0)">{{ index }}</a>
              </li>
              <li class="page-item" v-bind:class="ActiveNext">
                <a class="page-link" href="javascript:void(0);" v-on:click="PagingNext()">{{$lang.Common.DataTable.Next}}</a>
              </li>
            </ul>
          </nav>
          <div v-if="showPaging"><small>{{ShowDisplayingPaging}}</small></div>
        </div>
      </div>
    </div>
  </div>
  <TableEditMessage :showMessage="showTipFooter" :message="messageTipFooter"/>
</div>
</template>
<script>
import TableEditMessage from './TableEditMessage'

export default {
  name: 'Tables',
  data () {
    return {
      pagingIndex: 1,
      pagingLimit: 5,
      totalPages: 0,
      total: 0,
      startTotal: 0,
      cbxItemPerPage: 5,
      objList: null,
      currentPage: 1,
      loading: false,
      setFullscreen: true,
      ImgLoading: process.env.BaseURL + '/static/img/loader.gif'
    }
  },
  components: {
    TableEditMessage
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    showNew: {
      type: Boolean,
      required: false
    },
    showReload: {
      type: Boolean,
      required: false
    },
    showSearch: {
      type: Boolean,
      required: false
    },
    showPrint: {
      type: Boolean,
      required: false
    },
    showDownload: {
      type: Boolean,
      required: false
    },
    showFullscreen: {
      type: Boolean,
      required: false
    },
    showTipFooter: {
      type: Boolean,
      required: false,
      default: true
    },
    messageTipFooter: {
      type: String,
      required: false
    },
    showClose: {
      type: Boolean,
      required: false,
      default: false
    },
    showPaging: {
      type: Boolean,
      required: false,
      default: true
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ActivePrevious: function () {
      if (this.pagingIndex <= 1) {
        return 'disabled'
      } else {
        return ''
      }
    },
    ActiveNext: function () {
      if (this.currentPage >= this.totalPages) {
        return 'disabled'
      } else {
        return ''
      }
    },
    ShowRecordStart: function () {
      if (this.currentPage <= 1) {
        return this.currentPage
      } else {
        return (this.currentPage * this.pagingLimit) - this.pagingLimit
      }
    },
    ShowTotalPagedRecord: function () {
      if (this.currentPage >= this.totalPages) {
        return this.total
      } else {
        return this.currentPage * this.pagingLimit
      }
    },
    ShowDisplayingPaging: function () {
      var msg = this.$lang.Common.DataTable.MessageDisplayingRecords.replace('@START@', this.ShowRecordStart)
      msg = msg.replace('@END@', this.ShowTotalPagedRecord)
      msg = msg.replace('@TOTAL@', this.total)
      return msg
    }
  },
  methods: {
    Clear () {
      this.objList = null
      this.$parent.objList = null
      this.pagingIndex = 1
      this.pagingLimit = 5
      this.totalPages = 0
      this.total = 0
      this.startTotal = 0
      this.cbxItemPerPage = 5
      this.currentPage = 1
    },
    LoadAll () {
      this.$emit('Load')
    },
    LoadStartTotalGrid (total) {
      this.total = total
    },
    LoadAllByData (response) {
      this.objList = null
      this.$parent.objList = null
      this.loading = true

      if (response) {
        var result = response.data

        if (parseInt(result.code) >= 0) {
          this.objList = result.data
          this.$parent.objList = result.data
          this.total = result.count
          this.totalPages = result.totalPages
          this.pagingIndex = result.pagingIndex
          this.pagingLimit = result.pagingLimit
        }
      }
      this.loading = false
    },
    PagingNext () {
      var index = this.pagingIndex + 1
      if (this.currentPage < this.totalPages) {
        this.pagingIndex = index
        this.currentPage = this.currentPage + 1
        this.LoadAll()
      }
    },
    PagingPrevious () {
      var index = this.pagingIndex - 1
      if (this.currentPage > 1) {
        this.pagingIndex = index
        this.currentPage = this.currentPage - 1
        this.LoadAll()
      }
    },
    ActivePage (index) {
      if (index === this.currentPage) {
        return 'disabled'
      } else {
        return ''
      }
    },
    LoadPage (index) {
      this.currentPage = index
      this.pagingIndex = index // - 1
      if (this.currentPage >= 1 && this.currentPage <= this.totalPages) {
        this.LoadAll()
      }
    },
    ReloadCountPage () {
      this.pagingLimit = this.cbxItemPerPage
      this.pagingIndex = 1
      this.currentPage = 1
      this.LoadAll()
    },
    New () {
      this.$emit('New')
    },
    Reload () {
      this.$emit('Reload')
    },
    Search () {
      this.$emit('Search')
    },
    Print () {
      // variable to hold all the css that we extract
      var css = ''
      var styletags = document.getElementsByTagName('style')

      // loop over all the style tags
      for (var i = 0; i < styletags.length; i++) {
        // extract the css in the current style tag
        css += styletags[i].innerHTML
      }

      var currentsheet = false // initialise a variable to hold a reference to the stylesheet we are currently extracting from
      // loop over all the external stylesheets
      for (i = 0; i < document.styleSheets.lenngth; i++) {
        currentsheet = document.styleSheets[i]
        // loop over all the styling rules in this external stylesheet
        for (var e = 0; e < currentsheet.cssRules.length; e++) {
          // extract all the styling rules
          css += currentsheet.cssRules[e].cssText
        }
      }

      var headstr = '<!DOCTYPE html><html><head><meta charset="utf-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"><meta name="robots" content="noindex, nofollow"><title>SDS - Backoffice :: Impressão</title><link type="text/css" rel="stylesheet" media="print" href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"/><link href="static/css/style.min.css?1001" rel="stylesheet" media="print"><style>@media print \n{\n' + css + '\n body{ padding: 2px;} \n}</style></head>'
      var footstr = '</body>'
      var content = '<table class="table" style="border: 1px solid rgb(220, 222, 222);">' + this.$el.children['0'].children['0'].childNodes['0'].childNodes[2].children['0'].innerHTML + '</table>'
      var print = window.open('about:blank')

      print.document.write(headstr + content + footstr)
      print.window.print()
      print.window.close()
    },
    SaveFile () {
      // const data = JSON.stringify(this.objList)
      // const fs = require('fs');
      // try { fs.writeFileSync('download.txt', data, 'utf-8'); }
      // catch(e) { alert('Failed to save the file !'); }
      this.$emit('SaveFile')
    },
    Fullscreen () {
      if (this.setFullscreen) {
        this.setFullscreen = false
      } else {
        this.setFullscreen = true
      }
      this.$emit('Fullscreen')
    }
  }
}
</script>
